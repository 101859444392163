<template>
<div :class='formClassWrapper'
     class='b-appointment-left-bar_form b-booking-calendar-form'>
    <BookingFormHead
        :date='date'
        :dates='dates'
        :selectedAdvisor='selectedAdvisor'
        :iconsWrapperClass='iconsWrapperClass'
        :iconsInnerClass='iconsInnerClass'>
    </BookingFormHead>
    <BookingFormDistance
        :iconsInnerClass='iconsInnerClass'
        :distance_to_in_km_text='eventData.distance_to_in_km_text'
        :duration_to_in_min_text='eventData.duration_to_in_min_text'>
    </BookingFormDistance>
    <form class='h-mt-15'
          :class='formClass'
          @keyup.enter='submitForm'>
        <div class='b-form-base-line'>
            <FwFormInput
                class='b-form-base-line__sub-input qa-appointment-book-form__name'
                :fieldHasError='fieldHasError("static.first_name")'
                labelType='label-medium'
                :title='`${$t(`LABEL.FIRST.NAME`)}*`'>
                <FwInput
                    :value='static.first_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.FIRST.NAME`)'
                    inputStyle='white-bigger'
                    :disabled='isFormDisabled("static.first_name")'
                    :error='getFieldErrorText("static.first_name")'
                    @update:value='value => static.first_name = value'
                    @input='clearServerErrorsBase("static.first_name")'
                    @blur='addCurrentInputToValidateArray("static.first_name")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-form-base-line__sub-input qa-appointment-book-form__last-name'
                :fieldHasError='fieldHasError("static.last_name")'
                labelType='label-medium'
                :title='`${$t(`LABEL.LAST.NAME`)}*`'>
                <FwInput
                    :value='static.last_name'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.LAST.NAME`)'
                    inputStyle='white-bigger'
                    :disabled='isFormDisabled("static.last_name")'
                    :error='getFieldErrorText("static.last_name")'
                    @update:value='value => static.last_name = value'
                    @input='clearServerErrorsBase("static.last_name")'
                    @blur='addCurrentInputToValidateArray("static.last_name")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                v-if='!isApgisAccount'
                class='b-form-base-line__sub-input'
                :fieldHasError='fieldHasError("static.company")'
                labelType='label-medium'
                :title='`${$t(`LABEL.COMPANY`)}`'>
                <FwInput
                    :value='static.company'
                    type='tel'
                    name='text'
                    :placeholder='$t(`LABEL.COMPANY`)'
                    inputStyle='white-bigger'
                    :disabled='isFormDisabled("static.company")'
                    :error='getFieldErrorText("static.company")'
                    @update:value='value => static.company = value'
                    @input='clearServerErrorsBase("static.company")'
                    @blur='addCurrentInputToValidateArray("static.company")'>
                </FwInput>
            </FwFormInput>
        </div>
        <div class='b-form-base-line'>
            <FwFormInput
                class='b-form-base-line__sub-input qa-appointment-book-form__phone'
                :fieldHasError='fieldHasError("static.phone_number")'
                labelType='label-medium'
                :title='`${$t(`LABEL.PHONE`)}**`'>
                <FwInput
                    class='h-width-100p'
                    :value='static.phone_number'
                    type='number'
                    name='text'
                    :placeholder='$t(`LABEL.PHONE`)'
                    inputStyle='white-bigger'
                    :disabled='isFormDisabled("static.phone_number")'
                    :error='getFieldErrorText("static.phone_number")'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    :isPhoneWithISOCode='isGeoScopeInternationalWithCountries'
                    :locales='["fr", "ch"]'
                    :tryToUseSubstring='isGeoScopeInternationalWithCountries'
                    :phoneNumber='phoneWithPrefix || static.phone_number'
                    inputWidth='100%'
                    @substrPhone='value => static.phone_number = value'
                    @takePhoneWithPrefix='takePhoneWithPrefix'
                    @update:value='value => static.phone_number = value'
                    @input='clearServerErrorsBase("static.phone_number")'
                    @blur='addCurrentInputToValidateArray("static.phone_number")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                class='b-form-base-line__sub-input qa-appointment-book-form__email'
                :fieldHasError='fieldHasError("static.email")'
                labelType='label-medium'
                :title='`${$t(`LABEL.EMAIL`)}**`'>
                <FwInput
                    :value='static.email'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.EMAIL`)'
                    :error='getFieldErrorText("static.email")'
                    inputStyle='white-bigger'
                    :disabled='isFormDisabled("static.email")'
                    @update:value='value => static.email = value'
                    @input='clearServerErrorsBase("static.email")'
                    @blur='addCurrentInputToValidateArray("static.email")'>
                </FwInput>
            </FwFormInput>
            <template v-if='!isGeoScopeInternationalWithCountries'>
                <FwFormInput
                    v-if='post_code && client'
                    class='b-form-base-line__sub-input qa-appointment-book-form__post-code'
                    :fieldHasError='fieldHasError("post_code")'
                    labelType='label-medium'
                    :disabled='true'
                    :title='$t("SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER")'>
                    <FwInput
                        :value='post_code'
                        type='text'
                        name='text'
                        inputStyle='white-bigger'
                        :disabled='true'
                        :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                        placeholder='ex: 75001'
                        @blur='addCurrentInputToValidateArray("post_code")'>
                    </FwInput>
                </FwFormInput>
                <FwFormInput
                    v-else-if='!client'
                    class='b-form-base-line__sub-input qa-appointment-book-form__post-code-common'
                    :fieldHasError='fieldHasError("static.client_post_code")'
                    labelType='label-medium'
                    :title='`${$t(`SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER`)}***`'>
                    <FwInput
                        :value='static.client_post_code'
                        type='text'
                        name='text'
                        inputStyle='white-bigger'
                        :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                        placeholder='ex: 75001'
                        @update:value='value => static.client_post_code = value'
                        @blur='addCurrentInputToValidateArray("static.client_post_code")'>
                    </FwInput>
                </FwFormInput>
            </template>
        </div>
        <div v-if='isGeoScopeInternationalWithCountries' class='b-form-base-line'>
            <FwFormInput
                class='b-form-base-line__sub-input qa-appointment-book-form__country_iso'
                :fieldHasError='fieldHasError("static.country_iso")'
                labelType='label-medium'
                :title='`${$t(`LABEL.COUNTRY.ISO`)}**`'>
                <FwInput
                    :value='static.country_iso'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.COUNTRY.ISO`)'
                    :error='getFieldErrorText("static.country_iso")'
                    inputStyle='white-bigger'
                    :disabled='static.country_iso'
                    @update:value='value => static.country_iso = value'
                    @input='clearServerErrorsBase("static.country_iso")'
                    @blur='addCurrentInputToValidateArray("static.country_iso")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                v-if='post_code'
                class='b-form-base-line__sub-input qa-appointment-book-form__post-code'
                :fieldHasError='fieldHasError("post_code")'
                labelType='label-medium'
                :disabled='true'
                :title='$t("SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER")'>
                <FwInput
                    :value='post_code'
                    type='text'
                    name='text'
                    inputStyle='white-bigger'
                    :disabled='true'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    placeholder='ex: 75001'
                    @blur='addCurrentInputToValidateArray("post_code")'>
                </FwInput>
            </FwFormInput>
            <FwFormInput
                v-else-if='!client'
                class='b-form-base-line__sub-input qa-appointment-book-form__post-code-common'
                :fieldHasError='fieldHasError("static.client_post_code")'
                labelType='label-medium'
                :title='`${$t(`SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER`)}***`'>
                <FwInput
                    :value='static.client_post_code'
                    type='text'
                    name='text'
                    inputStyle='white-bigger'
                    :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                    placeholder='ex: 75001'
                    @update:value='value => static.client_post_code = value'
                    @blur='addCurrentInputToValidateArray("static.client_post_code")'>
                </FwInput>
            </FwFormInput>
        </div>
        <div v-if='accountData.appointment_attributes && accountData.appointment_attributes.length'
             class='b-form-base-line'>
            <FormAttributes
                class='b-booking-form__outer'
                clearable
                :disabled='false'
                :schemas='prepareAttributeSchema'
                @change='updateSchema'>
            </FormAttributes>
        </div>
        <div v-if='isBookCalendarOnSite'
             class='b-form-base-line'
             :class='{ "h-mh-15": !isMobile() }'>
            <AutocompleteSelector
                :startAddress='addressFieldText'
                :isDisabled='!!(addressFieldText) && !this.should_update_client'
                :postCodeNotNeed='Boolean(post_code)'
                :countryData='country'
                :isCountrySelector='false'
                requiredText='***'
                useDefaultCountryIso
                isFullSpace
                isRequired
                noMargin
                @input='input'
                @update='value => country = value'>
            </AutocompleteSelector>
        </div>
        <div v-if='!isAlmAccount' class='b-form-base-line'>
            <FwFormInput
                class='b-form-base-line__sub-input'
                :fieldHasError='fieldHasError("static.notes")'
                labelType='label-medium'
                :title='$t(`LABEL.NOTES`)'>
                <FwInput
                    :value='static.notes'
                    type='text'
                    name='text'
                    :placeholder='$t(`LABEL.NOTES`)'
                    :error='getFieldErrorText("static.notes")'
                    inputStyle='white-bigger'
                    :disabled='isFormDisabled("static.notes")'
                    @update:value='value => static.notes = value'
                    @input='clearServerErrorsBase("static.notes")'
                    @blur='addCurrentInputToValidateArray("static.notes")'>
                </FwInput>
            </FwFormInput>
        </div>
    </form>

    <div class='h-ph-15 h-mt-25'>
        <FwCheckbox
            v-if='isAppointmentClientShouldUpdate'
            v-model='should_update_client'
            :value='should_update_client'
            type='with-text'
            :itemWidth='`290px`'
            name='checkbox'>
            <div>
                {{ $t('PAST.APPOINTMENT.UPDATE.CLIENT.DETAILS') }}
            </div>
        </FwCheckbox>
        <FwCheckbox
            v-if='!isAlmAccount'
            v-model='skip_notifications'
            :value='skip_notifications'
            type='with-text'
            :itemWidth='`290px`'
            name='checkbox'>
            <div>
                {{ $t('APPOINTMENT.DISABLE.CUSTOMER.NOTIFICATION') }}
            </div>
        </FwCheckbox>
    </div>

    <div class='b-form-hint__wrapper h-flex-dir-column'>
        <div class='b-form-hint h-mb-10'>
            * {{ $t('WORKERS.FORM.HINT.ONE_OF_THESE') }}
        </div>
        <div class='b-form-hint h-mb-10'>
            ** {{ $t('WORKERS.FORM.HINT.ONE_OF_THESE') }}
        </div>
        <div v-if='!client'
             class='b-form-hint'>
            *** {{ $t('WORKERS.FORM.HINT.REQUIRED') }}
        </div>
    </div>

    <div class='h-pt-10 h-pb-20 h-ph-50'>
        <div class='h-flex h-flex-center'>
            <FwButton
                class='h-mt-10 qa-appointment-book-form__submit b-appointment-book-form__submit'
                borderRadiusType='small-border'
                :disabled='isDisable'
                @click='submitForm'>
                {{ bookButtonText }}
            </FwButton>
        </div>
        <div class='h-flex h-flex-center'>
            <FwButton
                class='h-mt-20'
                :style='{
                    color: `rgba(32, 63, 106, 0.3)`,
                    textTransform: `uppercase`
                }'
                color='transparent'
                fontSize='14px'
                @click.native='$emit(`close`)'>
                {{ $t('BUTTON.CANCEL') }}
            </FwButton>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { clone } from 'ramda';
import { namespace } from 'vuex-class';

import i18n from '@/locale';

import { BookingEventType } from '@/types/Availabilities';
import { FormBookAppointmentType } from '@/types/Book';
import { AppointmentOnSiteDataType, AppointmentSearchType, BranchAddressType } from '@/types/Appointment';
import { ClientType, UserListType } from '@/types/User';
import { ParsedAddressData } from '@/types/GoogleMap';
import { Country } from '@/types/Account';

import ValidationMixin from '@/mixins/validation';
import CalendarMixin from '@/mixins/calendar';
import DateMixin from '@/mixins/dateMixin';
import AccountMixin from '@/mixins/account';
import DynamicFormMixin from '@/mixins/dynamicFormMixin';
import PermissionsMixin from '@/mixins/permissions';

import { BookingFormHead } from '@/components/forms/nodes/BookingFormHead';
import { BookingFormDistance } from '@/components/forms/nodes/BookingFormDistance';
import { FormAttributes } from '@/components/forms/attributes';
import { AutocompleteSelector } from '@/components/nodes/AutocompleteSelector';

const AppointmentStore = namespace('AppointmentStore');

type StaticFormType = {
    first_name: string
    last_name: string
    company: string
    phone_number: string
    email: string
    address: string
    local_post_code: string
    notes: string
    client_post_code: string
    country_iso?: string
}

@Component({
    validators: {
        'static.first_name': function(value: string) {
            return this.baseInputValidator({ value, isRequired: !(this.static.last_name), requiredHint: i18n.t('VALIDATION.FIRST_OR_LAST_NAME.REQUIRED') });
        },
        'static.last_name': function(value: string) {
            return this.baseInputValidator({ value, isRequired: !(this.static.first_name), requiredHint: i18n.t('VALIDATION.FIRST_OR_LAST_NAME.REQUIRED') });
        },
        'static.phone_number': function(value: string) {
            return this.baseInputValidator({ value, isRequired: !(this.static.email), requiredHint: i18n.t('VALIDATION.EMAIL_OR_PHONE.REQUIRED') });
        },
        'static.email': function(value: string) {
            return this.emailValidator({ value, notRequired: Boolean(this.static.phone_number), requiredHint: i18n.t('VALIDATION.EMAIL_OR_PHONE.REQUIRED') });
        },
        'static.local_post_code': function(value: string) {
            return this.baseInputValidator({ value, isRequired: Boolean(this.post_code) });
        },
        'static.address': function(value: string) {
            return this.baseInputValidator({ value, isRequired: Boolean(this.isBookCalendarOnSite) });
        },
        'static.client_post_code': function(value: string) {
            return this.baseInputValidator({ value, isRequired: Boolean(!this.client) });
        },
    },
    components: {
        AutocompleteSelector,
        BookingFormHead,
        FormAttributes,
        BookingFormDistance,
    },
})
export default class BookingForm extends Mixins(DynamicFormMixin, ValidationMixin, CalendarMixin, DateMixin, AccountMixin, PermissionsMixin) {
    @AppointmentStore.State('appointmentDataToUpdate') appointmentDataToUpdate!: AppointmentSearchType | null;
    @AppointmentStore.State('appointmentOnSiteData') appointmentOnSiteData!: AppointmentOnSiteDataType | null;

    @Prop({ type: Object, default: null }) readonly defaultFormValues!: BookingEventType | null;
    @Prop({ type: Object, default: null }) readonly client!: ClientType;
    @Prop({ type: String, default: null }) readonly formClassWrapper!: string;
    @Prop({ type: String, default: null }) readonly formClass!: string;
    @Prop({ type: String, default: null }) readonly iconsWrapperClass!: string;
    @Prop({ type: Boolean, default: false }) readonly isRequestSending!: boolean;
    @Prop({ type: String, default: null }) readonly iconsInnerClass!: string;
    @Prop({ type: Object, required: true }) readonly eventData!: BookingEventType;
    @Prop({ type: Boolean, default: false }) readonly disabledForm!: boolean;
    @Prop({ type: Date, required: true }) readonly date!: Date;
    @Prop({ type: Array, required: true }) readonly dates!: Array<string>;
    @Prop({ type: Boolean, default: false }) readonly canUpdateDisabledFields!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isUpdateNowWithCurrentAdvisor!: boolean;
    @Prop({ type: Object, default: null }) readonly placeParsedData!: ParsedAddressData | null;
    @Prop({ type: Object, default: null }) readonly branchAddress!: BranchAddressType;
    @Prop({ type: String, default: null }) readonly post_code!: string;
    @Prop({ type: String, default: null }) readonly country_iso!: string;
    @Prop({ type: String, default: '' }) readonly start_email!: string;
    @Prop({ type: String, default: '' }) readonly start_phone_number!: string;
    @Prop({ type: String, default: '' }) readonly phoneWithPrefix!: string;
    @Prop({ type: Object, required: true }) readonly selectedAdvisor!: UserListType | null;

    static: StaticFormType = {
        first_name: ``,
        last_name: ``,
        company: ``,
        phone_number: ``,
        email: ``,
        address: ``,
        local_post_code: ``,
        notes: ``,
        client_post_code: ``,
    }

    serverErrors: serverErrors = {
        first_name: '',
        last_name: '',
        company: '',
        phone_number: '',
        email: '',
        ...(this.branchAddress && { address: '' }),
    };
    should_update_client: boolean = false;
    skip_notifications: boolean = false;
    country: Country | null | undefined = null;
    latitude: number | null = null;
    longitude: number | null = null;
    phoneWithPrefixToPayload: string = '';

    get isDisable(): boolean {
        return !this.isFormValid || this.isRequestSending || this.isErrorInForm;
    }

    get requiredFields(): Array<string> {
        const required = [];
        if (!this.static.first_name) {
            required.push('static.last_name');
        }
        if (!this.static.last_name) {
            required.push('static.first_name');
        }
        if (!this.static.phone_number) {
            required.push('static.email');
        }
        if (!this.static.email) {
            required.push('static.phone_number');
        }
        if (this.post_code) {
            required.push('static.local_post_code');
        }
        if (this.isBookCalendarOnSite) {
            required.push('static.address');
        }
        if (!this.client) {
            required.push(`static.client_post_code`);
        }
        return required;
    }

    get isAppointmentClientShouldUpdate() {
        return this.client && !this.appointmentDataToUpdate && !this.isAlmAccount;
    }

    get addressFieldText() {
        if (this.client && this.client.address) {
            return this.client.address;
        }

        if (this.appointmentOnSiteData && this.appointmentOnSiteData.location) {
            return this.appointmentOnSiteData.location;
        }

        return '';
    }

    get bookButtonText() {
        return this.$t(this.appointmentDataToUpdate ? 'BUTTON.SAVE' : 'LABEL.BOOK');
    }

    get isClientPostCode() {
        return !this.client || (this.isAppointmentClientShouldUpdate && this.isBookCalendarOnSite);
    }

    input(parsedAddressData: ParsedAddressData) {
        this.static.address = parsedAddressData.calizyAddress;
        this.addCurrentInputToValidateArray('static.address');
    }

    submitForm() {
        if (!this.isDisable) {
            this.onSubmit();
        }
    }

    isFormDisabled(key: string): boolean {
        if (this.isAppointmentClientShouldUpdate && !this.should_update_client) {
            return true;
        }
        // @ts-ignore-next-line
        if (!this.canUpdateDisabledFields && this.client && !this.client[key]) {
            return false;
        }
        return Boolean(this.disabledForm || this.client) && (!this.canUpdateDisabledFields || this.isAlmAccount);
    }

    mounted() {
        if (this.post_code) {
            this.addCurrentInputToValidateArray('static.local_post_code');
        }
        if (this.client) {
            for (let j = 0; j < this.requiredFields.length; j++) {
                this.addCurrentInputToValidateArray(this.requiredFields[j]);
            }
        }

        this.addCurrentInputToValidateArray('static.email');
    }

    created() {
        this.schema = clone(this.accountData.appointment_attributes);

        if (this.post_code) {
            this.static.local_post_code = this.post_code;
            this.static.client_post_code = this.post_code;
        }

        if (this.client) {
            this.static.first_name = this.client.first_name;
            this.static.last_name = this.client.last_name;
            this.static.phone_number = this.client.phone_number;
            this.static.email = this.client.email;
            if (!this.post_code && this.client.post_code) {
                this.static.local_post_code = this.client.post_code;
            }
            this.static.address = this.addressFieldText;
        } else {
            this.static.phone_number = this.start_phone_number;
            this.static.email = this.start_email;
            if (this.appointmentOnSiteData && this.appointmentOnSiteData.location) {
                this.static.address = this.appointmentOnSiteData.location;
            }
        }

        if (this.placeParsedData && this.placeParsedData.post_code) {
            this.static.client_post_code = this.placeParsedData.post_code;
        }

        if (this.appointmentDataToUpdate && this.appointmentDataToUpdate.notes) {
            this.static.notes = this.appointmentDataToUpdate.notes;
        }

        if (this.isGeoScopeInternationalWithCountries) {
            if (this.appointmentDataToUpdate && this.appointmentDataToUpdate.country_iso && !this.appointmentOnSiteData) {
                this.country = (this.geoScopeCountries as Array<Country>).find(country => country.country_iso === this.appointmentDataToUpdate!.country_iso);
            } else if (this.appointmentOnSiteData) {
                this.country = (this.geoScopeCountries as Array<Country>).find(country => country.country_iso === this.appointmentOnSiteData!.country_iso);
            }
            if (this.country_iso) {
                this.static.country_iso = this.country_iso;
            }
        }

        if (this.isUpdateNowWithCurrentAdvisor) {
            this.onSubmit();
        }
        this.serverErrors = { ...this.serverErrors, ...this.schema.reduce((o, item) => ({ ...o, [item.searchable_name]: null }), {}) };
    }

    takePhoneWithPrefix(phone: string) {
        this.phoneWithPrefixToPayload = phone;
    }

    @Emit('onSubmit')
    onSubmit(): FormBookAppointmentType {
        const updatedUserData = (this.client && this.should_update_client) || !this.client || this.appointmentDataToUpdate ? {
            first_name: this.static.first_name || null,
            last_name: this.static.last_name || null,
            company: this.static.company || null,
            phone_number: this.isGeoScopeInternationalWithCountries ? this.phoneWithPrefixToPayload : this.static.phone_number || null,
            email: this.static.email || null,
        } : null;
        const dynamicFields: { [key: string]: string } = {};
        if (this.schema && this.schema.length) {
            for (let i = 0; i < this.schema.length; i++) {
                const field = this.schema[i];
                if (field && field.value) {
                    dynamicFields[field.searchable_name] = field.value;
                }
            }
        }
        let payload = {};
        if (this.country_iso) {
            payload = {
                ...payload,
                country_iso: this.country_iso,
            };
        }
        if (this.latitude && this.longitude) {
            payload = {
                ...payload,
                latitude: this.latitude,
                longitude: this.longitude,
            };
        }
        return {
            ...updatedUserData,
            ...(this.isAppointmentClientShouldUpdate && this.should_update_client && { should_update_client: true }),
            ...((this.post_code || this.static.client_post_code) && { post_code: this.isClientPostCode ? this.static.client_post_code : this.post_code }),
            ...(this.isBookCalendarOnSite && { address: this.static.address }),
            notes: this.static.notes || null,
            skip_notifications: this.skip_notifications,
            ...dynamicFields,
            ...payload,
        };
    }

    @Watch('phone_number')
    phoneChangeReset() {
        this.$validate([`static.phone_number`, `static.email`]);
    }

    @Watch('email')
    emailChangeReset() {
        this.$validate([`static.phone_number`, `static.email`]);
    }

    @Watch('last_name')
    lastNameChangeReset() {
        this.$validate([`static.first_name`, `static.last_name`]);
    }

    @Watch('first_name')
    firstNameChangeReset() {
        this.$validate([`static.first_name`, `static.last_name`]);
    }
}
</script>

<style lang='sass'>
.b-booking-form
    &__input-wrapper
        flex: 0 0 33.33%
        padding: 0 15px
        margin-bottom: 20px

    &__outer
        margin: 20px 0 -10px
        width: 100%

.b-appointment-left-bar_form
    .fw-input--white, .fw-input--textarea
        box-shadow: none
        &::placeholder
            color: #909FB5

    .fw-input__error-text
        margin: 10px 0 15px
        font-size: 12px
        color: #FF004D

    .b-cancel-appointment__form
        .multiselect__tags
            min-height: 60px !important
            padding-left: 15px !important
            border-radius: 10px !important

    .fw-check-box__wrapper.with-text
        display: flex
        flex-direction: row
        width: 100%
        justify-content: flex-start
        margin-top: 10px

        .fw-check-box__label
            margin-left: 10px
            font-weight: 400
.b-booking-calendar-form
    .b-cancel-appointment__form
        .fw-select-phone-with-code
            .multiselect__tags
                min-height: 50px!important
                height: 50px!important
                border-bottom-right-radius: 0!important
                border-top-right-radius: 0!important
            .fw-select__caret
                top: 0

.b-appointment-left-bar__time

    &:not(:first-of-type)
        &:before
            content: '-'
            margin: 0 3px

.b-base-form-margin
    margin-bottom: 35px

    @include media('<tablet')
        margin-bottom: 10px

.h-align-items-end
    align-items: end

.b-appointment-left-bar_form
    .fw-input--white-bigger
        max-height: 54px !important
        min-height: 54px !important

@media (max-height: 900px)
    .b-appointment-left-bar_form
        margin-top: -15px

        .fw-input--white-bigger
            max-height: 50px !important
            min-height: 50px !important

    .b-cancel-appointment__form__events
        margin-bottom: 10px !important

    .b-form-hint.h-mb-10
        margin-bottom: 5px

    .b-form-hint__wrapper
        margin: 10px 15px 0px

    .b-appointment-book-form__submit
        margin-top: 0
        margin-bottom: -5px

.b-appointment-left-bar_form
    @include media('<=phone')
        width: 100%

.b-cancel-appointment__form__events
    flex-direction: column
</style>
