import { render, staticRenderFns } from "./BookCalendarEventCard.vue?vue&type=template&id=4ca3f55e"
import script from "./BookCalendarEventCard.vue?vue&type=script&lang=ts"
export * from "./BookCalendarEventCard.vue?vue&type=script&lang=ts"
import style0 from "./BookCalendarEventCard.vue?vue&type=style&index=0&id=4ca3f55e&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports