<template>
<div class='b-user-list qa-user-list'>
    <div class='b-user-list__inner h-pv-10'>
        <div v-for='(user, index) in localList'
             :key='index'
             class='b-user-list__item'
             :class='{
                 "b-user-list__item--active": userFilterId === user.id && !user.disabled,
                 "b-user-list__item--disabled": user.disabled,
             }'
             @click='emitClick(user)'>
            <div :style='{ "backgroundColor": userColor(user) }'
                 class='b-user-list__item__avatar'>
                <FwIcon
                    v-if='!user.avatar_url'
                    class='b-worker__avatar__icon'
                    icon='no-avatar'
                    size='10'
                    color='#fff'>
                </FwIcon>
                <WorkerAvatar
                    v-else
                    class='h-mv-15'
                    :avatarSize='22'
                    :hideInfo='true'
                    :worker='user'
                    size='small'
                    align='small'>
                </WorkerAvatar>
            </div>

            <div v-if='isAlmAccount && typeof user.appointment_count === "number"'
                 class='b-user-list__item__count'>
                {{ user.appointment_count }}
            </div>

            <div class='b-user-list__item__text'>
                {{ $t(user.name) | capitalizeAll }}
            </div>

            <div v-if='user.last_name' class='b-user-list__item__text'>
                {{ $t(user.last_name) | capitalizeAll }}
            </div>

            <div v-if='user.pro_title' class='b-user-list__item__label b-user-list__item__text'>
                {{ user.pro_title }}
            </div>
            <div class='b-user-list__item-by--hover'>
                <div class='b-user-list__item__text'>
                    {{ $t(user.name) | capitalizeAll }}
                </div>
                <div v-if='user.pro_title' class='b-user-list__item__label'>
                    {{ user.pro_title }}
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import AccountMixin from '@/mixins/account';
import { UserListType } from '@/types/User';
import { userAll, userAllDisabled } from '@/mocks/user';
import { hexToRgbA } from '@/helpers/colors';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';

@Component({
    components: {
        WorkerAvatar,
    },
})
export default class UserList extends Mixins(AccountMixin) {
    @Prop({ type: Array, required: true }) readonly users!: Array<UserListType>;
    @Prop({ type: String, default: null }) readonly userFilterId!: string | null;

    get localList(): Array<UserListType> {
        if (this.users.length) {
            return [userAll, ...this.users];
        }
        return [userAllDisabled, ...this.users];
    }

    emitClick(user: UserListType) {
        if (user.disabled) return;
        this.$emit(`click`, user.id);
    }

    userColor(user: UserListType): string {
        if (!user.color) {
            return '#27DBBD';
        }
        try {
            const color = hexToRgbA(user.color.split(' - ')[0] || user.color, 0.8);
            return color;
        } catch (e) {
            console.log(e);
        }
        return user.color || '#27DBBD';
    }
}
</script>

<style lang='sass'>
.b-user-list__main--scroll
    &-list
        align-items: flex-start
        height: 170px

    .fw-arrow-big-circle--middle-size
        margin-top: 20px

    .fw-horizontal-scroll__content
        position: initial !important

    .b-user-list
        padding-right: 50px
        margin-left: 0 !important
        padding-left: 75px

        @include media('<=phone')
            padding-left: 15px
            max-width: 100vw
            overflow: auto
            padding-right: 5px

        &__inner
            margin: 0
            display: flex
            cursor: pointer
            padding-right: 25px

        &__item__text
            z-index: 1
            width: 100%
            font-size: 11px
            text-align: center
            color: $dark-blue
            font-weight: 500
            white-space: nowrap
            overflow: hidden
            text-overflow: ellipsis
            will-change: transform
            transition: transform 0.15s ease-in-out

        &__item__avatar
            width: 20px
            height: 20px
            border-radius: 10px
            margin-bottom: 6px
            opacity: 0.7
            display: flex
            align-items: center
            justify-content: center
            will-change: transform
            transition: transform 0.15s ease-in-out

        &__item
            width: 80px!important
            height: 75px!important
            border: 1px solid rgba(33,63,107,0.1)
            background-color: #fff
            border-radius: 10px
            display: flex
            flex-direction: column
            justify-content: flex-start!important
            align-items: center
            margin: 0 5px
            padding: 5px
            transition: background-color 0.3s
            position: relative!important

            &__count
                position: absolute
                top: 0px
                right: 0px
                font-size: 11px
                padding: 3px
                background-color: #27dbbd
                color: #fff
                border-radius: 4px 10px 4px 4px

            &:hover:not(:first-child) .b-user-list__item-by--hover
                display: flex
                flex-flow: column
                z-index: 31

            &--active
                background-color: lighten($main-green, 40)
                border-color: transparent !important
                position: relative

                &:after
                    content: ''
                    left: 0
                    right: 0
                    width: 20px
                    height: 2px
                    background-color: $main-green
                    bottom: -11px
                    transform: translateY(-48%)
                    position: relative
                    margin: 0 auto
                    display: flex
                    justify-content: center
                    align-items: center

                .b-user-list__item__text
                    color: $main-green

            &--disabled
                background-color: lighten($main-gray, 17) !important
                border-color: transparent !important
                cursor: default !important

                &:after
                    content: ''
                    position: absolute
                    left: 0
                    right: 0
                    width: 20px
                    height: 2px
                    background-color: $main-gray
                    bottom: 0
                    margin: auto

            &:not(.b-user-list__item--disabled):hover
                border-color: transparent
                background-color: lighten($main-green, 45)
            &__label
                margin-top: 3px
                font-size: 9px
                padding: 2px 5px
                border-radius: 3px
                background-color: #F3F4F7
                color: $dark-blue
                font-weight: 500
                white-space: nowrap
                overflow: hidden
                text-overflow: ellipsis
                will-change: transform
                transition: transform 0.15s ease-in-out
            &-by--hover
                display: none
                padding: 10px
                position: absolute
                border-radius: 5px
                top: 80px
                background-color: #fff
                box-shadow: 0 0 10px 0 rgba(0,0,0,0.1)
                color: $dark-blue
                align-items: center
                transition: opacity 0.15s ease-in-out
                border: 1px solid rgba(33, 63, 107, 0.1)
                &:after
                    content: " "
                    position: absolute
                    top: 2%
                    left: 50%
                    margin-left: 7px
                    border: 5px solid
                    border-color: transparent transparent #fff #fff
                    transform-origin: 0 0
                    transform: rotate(135deg)
                    box-shadow: -3px 3px 3px -3px rgba(0,0,0,0.1)
                .b-user-list__item__text
                    font-size: 14px
                .b-user-list__item__label
                    font-size: 12px
                    padding: 5px
    & + div
        margin-top: -80px
</style>
