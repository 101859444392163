<template>
<div class='b-choose-branch'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <div
        :style='{ opacity: loading ? 0 : 1 }'
        class='h-flex-center h-flex-dir-column'>
        <GoBackButton
            v-if='isDirectBooking'
            style='top: 40px; left: 40px'
            class='b-route-wrapper__back_arrow'
            :method='toMethodPage'
            @click.native.stop.prevent>
        </GoBackButton>
        <div class='h-text-center h-pt-30'>
            <h1>
                {{ InServicePointFlow ? $t('SELECT.SERVICE_POINT.TITLE') : $t('SELECT.ADDRESS.BRANCH.TITLE') }}
            </h1>
        </div>
        <div
            style='align-items: flex-start; max-width: 760px'
            class='h-flex h-flex-center h-pos-rel'>
            <div v-if='isGeoScopeInternationalWithCountries'
                 style='width: 320px'
                 class='b-address-branch--select fw-select-base'>
                <FwSelect
                    v-model='country'
                    class='fw-select-white qa-select-appointment-skill'
                    :propsPlaceholder='$t(`LABEL.SELECT.COUNTRY`)'
                    type='white'
                    label='name'
                    :searchable='false'
                    :canNotDelete='true'
                    :options='geoScopeCountries'>
                </FwSelect>
            </div>
            <div class='fw-select-address h-ph-15'>
                <div class='h-width-100p'>
                    <form class='h-flex-center'
                          @keyup.prevent.enter='getBranches({ currentPage, perPage, isSubmit: true })'
                          @submit.prevent.stop>
                        <FwInput
                            v-focus='true'
                            class='h-flex-1 qa-select-appointment-branch-address input'
                            :value='searchText'
                            :placeholder='$t(`SELECT.POSTCODE.APPOINTMENT.PLACEHOLDER`)'
                            iconRight='loupe'
                            iconSize='15'
                            :propsKeyDownMethod='propsKeyDownMethodOnlyNumbers'
                            inputStyle='white-bigger'
                            type='search'
                            @update:value='value => searchText = value'>
                        </FwInput>
                        <FwButton
                            class='b-address-branch__submit qa-select-appointment-branch-address__submit h-ml-20'
                            :disabled='isFormDisabled'
                            borderRadiusType='small-border'
                            @click='getBranches({ currentPage, perPage, isSubmit: true })'>
                            {{ $t('BUTTON.SUBMIT') }}
                        </FwButton>
                    </form>
                </div>
                <div v-if='options.length'
                     ref='infiniteScroll'
                     class='b-address-branch__option__wrapper '
                     :class='{ "h-pos-abs": isGeoScopeInternationalWithCountries }'>
                    <div v-for='option in options'
                         class='b-address-branch__option qa-select-appointment-branch-option'
                         :class='{ "multiselect__content-item--active-item": client && option.external_id === client.branch_external_id }'
                         @click.stop.prevent='goToInterventionPage(option)'>
                        <div v-if='isDistanceExistInOptions' class='h-mr-20 b-distance-block'>
                            <div class='h-opacity-0_5 h-font-14'>
                                {{ `${option.distance} km` }}
                            </div>
                        </div>
                        <div class='multiselect__item-header h-flex h-flex-dir-column b-multiselect__item-header'>
                            <span class='b-address-branch__option__name'>
                                {{ option.name | capitalizeAll }}
                            </span>
                            <span class='b-address-branch__option__address'>
                                {{ option.address }}
                            </span>
                        </div>
                        <FwIcon
                            class='h-flex-center h-pointer h-mh-15'
                            icon='arrow-right'
                            size='14'
                            color='#27DBBD'>
                        </FwIcon>
                    </div>
                </div>
            </div>
        </div>
        <FwSpinLoader
            v-if='optionsLoading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='optionsLoading'
            className='h-pt-30 h-mt-15'>
        </FwSpinLoader>
    </div>
</div>
</template>

<script lang='ts'>
import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { ParsedAddressData } from '@/types/GoogleMap';
import {
    BranchAddressType,
    BranchByPostCodeItem,
    AppointmentCategoryType,
    CategoryLocationSkillNameType,
    AppointmentSearchType,
    AppointmentOnSiteDataType,
    BranchByDistance,
} from '@/types/Appointment';
import { ClientType } from '@/types/User';

import GoogleApi from '@/mixins/google-api';
import PermissionsMixin from '@/mixins/permissions';
import NoDataMixin from '@/mixins/no-data-mixin';
import ValidationMixin from '@/mixins/validation';
import AppointmentMixin from '@/mixins/appointment';
import AccountMixin from '@/mixins/account';

import { SitesApi } from '@/api/sites/SitesApi';
import {
    FLOWS_WITH_ROOM_ID_REQUIRE,
} from '@/helpers/appointment';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import { Country } from '@/types/Account';
import { GoBackButton } from '@/components/simple/GoBackButton';
import { BaseMetaType } from '@/types/Response';

const AppointmentStore = namespace('AppointmentStore');
const BreadCrumbsStore = namespace('BreadCrumbsStore');
const CalendarStore = namespace('CalendarStore');

@Component({
    components: { GoBackButton },
})
export default class AppointmentChooseBranchPage extends Mixins(GoogleApi, AppointmentMixin, NoDataMixin, ValidationMixin, PermissionsMixin, AccountMixin) {
    @BreadCrumbsStore.Mutation('setFlowKey') setFlowKey!: (key: string) => void;
    @AppointmentStore.Mutation('setBranchAddress') setBranchAddress!: (branchAddress: BranchAddressType) => void;
    @CalendarStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;
    @CalendarStore.Mutation('setCountryIso') setCountryIso!: (country_iso: string) => void;
    @AppointmentStore.Mutation('setAppointmentCategory') setAppointmentCategory!: (categoryName: AppointmentCategoryType) => void;
    @AppointmentStore.State('locationName') locationName!: CategoryLocationSkillNameType;
    @AppointmentStore.State('appointmentCategory') appointmentCategory!: AppointmentCategoryType;
    @AppointmentStore.State('appointmentDataToUpdate') appointmentDataToUpdate!: AppointmentSearchType | null;
    @AppointmentStore.State('placeParsedData') placeParsedData!: ParsedAddressData;
    @AppointmentStore.Mutation('setAppointmentOnSiteData') setAppointmentOnSiteData!: (appointment: AppointmentOnSiteDataType | AppointmentSearchType) => void;
    @AppointmentStore.State('client') client!: ClientType | null;
    @CalendarStore.State('post_code') post_code!: string;

    @Prop({ type: Boolean, default: false }) readonly isDirectBooking!: boolean;

    loading: boolean = true;
    isButtonActiveOn: boolean = false;
    optionsLoading: boolean = false;
    searchText: string = '';
    lastSearchCode: string = '';
    options: (BranchByPostCodeItem | BranchByDistance)[] = [];
    currentPage: number = 1;
    perPage: number = 20;
    totalCount: number = 0;
    country: Country | null = null;
    meta: BaseMetaType | null = null;

    $refs!: {
        infiniteScroll: HTMLElement
    };

    get isFormNotValid() {
        if (!this.searchText.length) {
            return true;
        }
        if (!this.isGeoScopeInternationalWithCountries && this.searchText.length !== 5) {
            return true;
        }
        return (this.lastSearchCode === this.searchText && !this.isButtonActiveOn && !this.meta?.next_page);
    }

    get isFormDisabled() {
        if (this.geoScopeInternational) {
            return this.isFormNotValid || !this.country;
        }

        return this.isFormNotValid;
    }

    get isDistanceExistInOptions() {
        return this.options.some(item => 'distance' in item && item.distance !== 0);
    }

    scroll(e: any) {
        if (e && e.target) {
            const element = e.target;
            if (element.offsetHeight + element.scrollTop + 25 >= element.scrollHeight &&
                this.totalCount > this.options.length) {
                this.getBranches({ currentPage: this.currentPage, perPage: this.perPage });
            }
        }
    }

    async getBranches({ currentPage, perPage, isSubmit = false }: { currentPage: number, perPage: number, isSubmit?: boolean }) {
        if (this.isFormDisabled) return;
        if (this.options.length) {
            this.$refs.infiniteScroll.removeEventListener('scroll', this.scroll);
        }
        if (isSubmit) {
            this.options = [];
            currentPage = 1;
            this.totalCount = 0;
        }
        if (this.searchText) {
            this.optionsLoading = true;
            try {
                const branch_external_id = this.client ? this.client.branch_external_id : null;
                let sites, meta;
                if (this.InServicePointFlow && this.isAlmAccount) {
                    const data = await SitesApi.getBranchesByPostCodeWithDistance(
                        {
                            post_code: this.searchText,
                            kind: 'service_point',
                            current_page: currentPage,
                            per_page: perPage,
                            ...this.client &&
                                this.client!.longitude &&
                                this.client!.latitude &&
                                this.client!.post_code === this.searchText ? { origin: `${this.client!.longitude},${this.client!.latitude}` } : {},
                        });

                    this.setPostCode(this.searchText);
                    // eslint-disable-next-line prefer-destructuring
                    sites = data.sites;
                } else if (this.InServicePointFlow) {
                    const data = await SitesApi.getServicePointsByPostCode(
                        this.searchText,
                        {
                            current_page: currentPage,
                            per_page: perPage,
                        },
                    );
                    sites = data.service_points;
                    ({ meta } = data);
                } else {
                    ({ sites, meta } = await SitesApi.getBranchesByPostCode(
                        this.searchText,
                        FLOWS_WITH_ROOM_ID_REQUIRE.includes(this.appointmentType),
                        branch_external_id && this.client && this.client.post_code === this.searchText && !this.isButtonActiveOn ? branch_external_id : null,
                        {
                            current_page: currentPage,
                            per_page: perPage,
                            ...this.country && this.country.country_iso ? { country_iso: this.country.country_iso } : {},
                        },
                        'branch',
                        this.isMatmutAccount,
                        this.locationName?.searchable_name ? this.locationName.searchable_name : null,
                    ));
                }
                if (branch_external_id) {
                    if (!sites.length && this.client && this.client.post_code === this.searchText) {
                        this.isButtonActiveOn = true;
                    }
                    this.options = [...this.options, ...this.client ? sites.sort((x, y) => {
                        /* eslint-disable-next-line no-nested-ternary */
                        return x.external_id === branch_external_id ? -1 : y.external_id === branch_external_id ? 1 : 0;
                    }) : sites];
                } else {
                    this.options = [...this.options, ...sites];
                }
                this.meta = (meta as BaseMetaType) || null;
                if (meta && meta.next_page) {
                    this.currentPage = meta.next_page;
                    this.totalCount = meta.total_count;
                }
                this.lastSearchCode = this.searchText;
                if (!sites.length) {
                    this.sentNotif('LABEL.HAVE.NOT.DATA.FOR.POST_CODE');
                }
                if (this.options.length && this.totalCount > this.perPage) {
                    this.$nextTick(() => {
                        if (this.$refs.infiniteScroll) {
                            this.$refs.infiniteScroll.addEventListener('scroll', this.scroll, false);
                        }
                    });
                }
            } catch ({ response }) {
                this.optionsLoading = false;
                this.sentNotif(response);
            } finally {
                this.optionsLoading = false;
            }
        }
    }

    async goToCalendarWithOnSite(option: BranchAddressType) {
        this.setAppointmentCategory({ ...this.appointmentCategory, site_id: option.id });
        const additionalData = this.country && this.country.country_iso ? { country_iso: this.country.country_iso } : {};

        try {
            const appointment = await AppointmentWebApi.onSiteInBranchAppointments({
                account_id: this.user().account_id,
                appointment_category_id: this.appointmentCategory.id,
                post_code: option.post_code || '',
                site_id: option.id || '',
                category: 'location_category_on_site_in_branch',
                ...(this.client && this.client.id && { client_id: this.client.id }),
                ...additionalData,
            });

            this.setAppointmentOnSiteData({ ...appointment, ...additionalData });
            this.$router.push({ path: '/appointment/calendar/in-branch-on-site' });
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async mounted() {
        if (this.appointmentDataToUpdate && !this.isOnSiteInBranchFlow) {
            this.setPostCode('');
            this.setCountryIso('');
        }

        if (!this.appointmentCategory || !this.locationName) {
            this.$_NoDataMixin_bookFlowNoData();
        }

        if ((this.client && this.client.post_code) || this.post_code) {
            if (this.client && this.client.post_code && this.post_code === this.client.post_code) {
                this.searchText = this.client.post_code;
            } else if (!this.isOnSiteInBranchFlow) {
                this.searchText = this.post_code;
            }

            if (this.searchText) {
                this.getBranches({ currentPage: this.currentPage, perPage: this.perPage, isSubmit: true });
            }
        }

        this.loading = false;
    }

    goToInterventionPage(branchAddress: BranchAddressType) {
        if (this.isDirectBooking) {
            this.$emit('updateBranchAddress', branchAddress);
        } else {
            this.setBranchAddress(branchAddress);

            if (!this.isOnSiteInBranchFlow) {
                if (this.country && this.country.country_iso) {
                    this.setCountryIso(this.country.country_iso);
                }

                if (branchAddress.post_code) {
                    this.setPostCode(branchAddress.post_code);
                }
            }
            if (this.isFaceToFaceFlow) {
                this.$router.push({ path: `/appointment/calendar/in-branch-face-to-face` });
            } else if (this.isOnSiteInBranchFlow) {
                this.goToCalendarWithOnSite(branchAddress);
            } else if (this.isVideoconferenceFlow) {
                this.$router.push({ path: `/appointment/calendar/in-branch-videoconference` });
            } else if (this.isBranchPrivateVideoFlow) {
                this.$router.push({ path: `/appointment/calendar/in-branch-face-to-face-private` });
            } else if (this.isBranchByPhonePublicFlow) {
                this.$router.push({ path: `/appointment/calendar/in-branch-by-phone-public` });
            } else if (this.isBranchByPhonePrivateFlow) {
                this.$router.push({ path: `/appointment/calendar/in-branch-by-phone-private` });
            } else if (this.isBranchByVideoPublicFlow) {
                this.$router.push({ path: `/appointment/calendar/in-branch-by-video-public` });
            } else if (this.isBranchByVideoPrivateFlow) {
                this.$router.push({ path: `/appointment/calendar/in-branch-by-video-private` });
            } else if (this.InServicePointFlow) {
                this.$router.push({ path: `/appointment/calendar/in-service-point` });
            }
        }
    }

    @Emit('toMethodPage')
    toMethodPage() {}

    @Watch('country')
    updateValue() {
        this.searchText = '';
        this.options = [];
        this.currentPage = 1;
        this.totalCount = 0;
        this.isButtonActiveOn = false;
    }
}
</script>

<style lang='sass'>
.b-choose-branch
    .fw-select-white
        box-shadow: none!important
.fw-select-address .multiselect .fw-select__caret
    opacity: 0

.fw-select-address
    .fw-select-white
        box-shadow: none!important

.b-branch-office-map-size
    width: 390px
    height: 400px

.fw-select-address .multiselect__placeholder
    padding-top: 0 !important

.b-multiselect__item-header
    width: calc(100% - 35px)

.multiselect__content-item--active-item
    background-color: rgba(33, 63, 107, 0.15)
    path
        fill: #213F6B !important

    .b-address-branch__option__name, .b-address-branch__option__address
        color: #213F6B

.b-distance-block
    padding-top: 5px
    white-space: nowrap
    flex: 0 0 62px

.b-address-branch__option
    display: flex
    padding: 15px
    border-bottom: 1px solid #a2a8b1
    cursor: pointer

    svg
        will-change: transform
        transition: transform 0.26s
        transform: translate(0px)

    &:hover
        .b-address-branch__option__name, .b-address-branch__option__address
            color: #203f6a

        path
            fill: #203f6a !important

        svg
            transform: translate(4px)

    &__name
        font-size: 20px
        font-weight: bold
        margin-bottom: 7px
        color: #27DBBD

    &__address
        font-weight: 300
        color: #a2a8b1
        text-transform: uppercase

    &__wrapper
        width: 100%
        left: 0
        max-height: 355px
        overflow-y: auto
        margin-top: 10px

    .fw-button--little
        padding: 10px 24px 10px
</style>
