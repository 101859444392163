var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"b-management-card",class:{
         "b-management-card--small": _vm.data.height <= 15,
         "b-management-card--big": _vm.data.height >= 45,
         "b-management-card--danger": _vm.data.danger,
         "b-management-card--alt": _vm.data.alt,
         "b-management-card--success": _vm.data.success,
         "b-management-card--lunch-break": _vm.data.origin === _vm.$options.LUNCH_BREAK_ID,
         "b-management-card--unavailability": _vm.data.origin === _vm.$options.UNAVAILABILITY_ORIGIN,
     }},[_c('div',{staticClass:"b-management-card__time-wrapper"},[_c('div',{staticClass:"b-management-card__label",style:({
                 borderColor: _vm.color
             })}),_c('div',{staticClass:"b-management-card__time"},[_c('div',[_vm._v(" "+_vm._s(_vm.startDate)+" ")]),_c('div',{staticClass:"b-management-card__title__wrapper"},[_c('div',{staticClass:"b-management-card__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.$options.AVAILABLE_FOR_DRAG.includes(_vm.data.id))?_c('FwImage',{staticClass:"b-management-card__icon",attrs:{"height":"14px","width":"14px","src":_vm.requireImage("car.png")}}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }