import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import {
    CategoryLocationSkillNameType,
    AppointmentCategoryType,
    GeograficCitiesType,
    CategoryBranchHubType,
    BranchAddressType,
    AppointmentOnSiteDataType,
    AppointmentSearchType,
    AppointmentRoomsType,
} from '@/types/Appointment';
import { ClientType } from '@/types/User';
import { BranchSitesDataType } from '@/types/Site';
import { GoogleMapPlaceType, ParsedAddressData } from '@/types/GoogleMap';
import { RouteType } from '@/types/router';
import { BRANCH_KEY, HUB_KEY, BRANCH_ADDRESS_KEY } from '@/helpers/consts';
import { sortGeograficPlaces } from '@/helpers/geografic';

@Module({
    namespaced: true,
})
class AppointmentStore extends VuexModule {
    appointmentCategory: AppointmentCategoryType | null = null;
    appointmentRoom: AppointmentRoomsType | null = null;
    locationName: CategoryLocationSkillNameType | null = null;
    appointmentPlace: GoogleMapPlaceType | null = null;
    branchData: BranchSitesDataType | null = null;
    branchAddress: BranchAddressType | null = null;
    appointmentDataToUpdate: AppointmentSearchType | null = null;
    hub: CategoryBranchHubType | null = null;
    placeParsedData: ParsedAddressData | null = null;
    appointmentOnSiteData: AppointmentOnSiteDataType | null = null;
    geograficCitiesData: Array<GeograficCitiesType> = [];
    client: ClientType | null = null;
    currentAppointmentKindState: string = '';
    appointmentAddress: string = '';
    appointmentType: string = '';
    opportunityId: string = '';
    post_code: string = '';
    caseId: string = '';
    isCountry: boolean = false;
    viewedSlotsList: Array<string> = [];
    initialRoute: RouteType | null = null;

    @Mutation
    clearAppointmentState() {
        this.appointmentCategory = null;
        this.locationName = null;
        this.appointmentPlace = null;
        this.branchData = null;
        this.branchAddress = null;
        this.hub = null;
        this.placeParsedData = null;
        this.appointmentOnSiteData = null;
        this.geograficCitiesData = [];
        this.appointmentType = '';
        this.appointmentAddress = '';
        this.post_code = '';
        this.client = null;
    }

    @Mutation
    clearPlaceParsedData() {
        this.placeParsedData = null;
    }

    @Mutation
    setCurrentAppointmentKind(appointmentKind: string) {
        this.currentAppointmentKindState = appointmentKind;
    }

    @Mutation
    setClientData(client: ClientType | null) {
        this.client = client;
    }

    @Mutation
    setAppointmentType(type: string) {
        this.appointmentType = type;
    }

    @Mutation
    setWholeCountry(item: boolean) {
        this.isCountry = item;
    }

    @Mutation
    setAppointmentCategory(appointmentCategory: AppointmentCategoryType) {
        this.appointmentCategory = appointmentCategory;
    }

    @Mutation
    setAppointmentRoom(appointmentRoom: AppointmentRoomsType) {
        this.appointmentRoom = appointmentRoom;
    }

    @Mutation
    setOpportunityId(id: string) {
        this.opportunityId = id;
    }

    @Mutation
    setCaseId(id: string) {
        this.caseId = id;
    }

    @Mutation
    setLocationName(locationName: CategoryLocationSkillNameType) {
        this.locationName = locationName;
    }

    @Mutation
    setAppointmentPlace(place: GoogleMapPlaceType) {
        this.appointmentPlace = place;
    }

    @Mutation
    setAppointmentDataToUpdate(appointment: AppointmentSearchType | null) {
        this.appointmentDataToUpdate = appointment;
    }

    @Mutation
    updateViewedSlotsList(id: string): void {
        this.viewedSlotsList = this.viewedSlotsList.includes(id) ? this.viewedSlotsList : [...this.viewedSlotsList, id];
    }

    @Mutation
    cleanViewedSlotsList(): void {
        this.viewedSlotsList = [];
    }

    @Mutation
    setPostCode(post_code: string) {
        this.post_code = post_code;
    }

    @Mutation
    removeAppointmentDataToUpdate() {
        this.appointmentDataToUpdate = null;
    }

    @Mutation
    setBranchAddress(branchAddress: BranchAddressType) {
        this.branchAddress = branchAddress;
        window.localStorage.setItem(BRANCH_KEY, JSON.stringify(branchAddress));
    }

    @Mutation
    setAppointmentAddress(address: string) {
        this.appointmentAddress = address;
    }

    @Mutation
    setBranchData(siteData: BranchSitesDataType) {
        this.branchData = siteData;
        window.localStorage.setItem(BRANCH_ADDRESS_KEY, JSON.stringify(siteData));
    }

    @Mutation
    setHubData(siteData: CategoryBranchHubType) {
        this.hub = siteData;
        window.localStorage.setItem(HUB_KEY, JSON.stringify(siteData));
    }

    @Mutation
    setPlaceParsedData(parsedAddressData: ParsedAddressData | null) {
        this.placeParsedData = parsedAddressData;
    }

    @Mutation
    setGeograficCitiesData(citiesData: Array<GeograficCitiesType>) {
        // @ts-ignore-next-line
        this.geograficCitiesData = sortGeograficPlaces(citiesData);
    }

    @Mutation
    setAppointmentOnSiteData(appointment: AppointmentOnSiteDataType) {
        this.appointmentOnSiteData = appointment;
    }

    @Mutation
    setInitialRouteData(route: RouteType | null) {
        this.initialRoute = route;
    }
}

export default AppointmentStore;
