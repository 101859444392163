<template>
<div class='b-blue-background b-blue-background__opacity b-blue-background--fixed b-appointment-management-popup'>
    <FwPopup
        class='h-pos-rel'
        :centered='false'
        :isFixed='false'
        iconColor='#BEC7D4'
        :max-width='hasAltSlotTtoShow ? "1000px" : "500px"'
        :width='hasAltSlotTtoShow ? "1000px" : "500px"'
        :padding='isMobile() ? "30px 20px" : "18px"'
        :title='title'
        :allowBackgroundScrolling='true'
        :showClosePopup='true'
        popupBackground='#fff'
        popupRadius='20px'
        @close='$emit("close")'>
        <template #body>
            <FwSpinLoader
                v-if='isLoading'
                :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
                class='h-flex-center loader'
                :isActive='isLoading'
                className='h-p-20'>
            </FwSpinLoader>
            <div v-else>
                <div class='b-appointment-management-popup__worker'>
                    <WorkerAvatar
                        hide-info
                        :avatarSize='24'
                        :worker='advisor'>
                    </WorkerAvatar>
                    <div>
                        {{ advisor.name }}
                    </div>
                </div>
                <div class='b-appointment-management-popup__tables__wrapper'>
                    <div class='b-app-management-table__inner'>
                        <div v-if='hasAltSlotTtoShow'
                             class='b-app-management-table__title'>
                            {{ $t('APPOINTMENT.MANAGEMENT.OPTION_SELECTED') }}
                        </div>
                        <div class='b-app-management-table'
                             :class='{ "b-app-management-table--dragged": !!draggedCard }'>
                            <div class='b-app-management-table__draggable__border'
                                 :style='workingTimeBorderStyles'>
                            </div>
                            <div class='b-app-management-table__time__wrapper'>
                                <div v-for='(data, timeIndex) in tableTime'
                                     :key='`${data.time}_${timeIndex}`'
                                     class='b-app-management-table__time'>
                                    {{ data.calendarViewTime }}
                                </div>
                            </div>
                            <div class='h-width-100p h-pos-rel'>
                                <div v-if='breakEventData'
                                     :style='calculateFullPosition(breakEventData)'
                                     :class='{
                                         "b-drag-forbidden": false,
                                         "b-drag-available": false,
                                     }'
                                     class='b-app-management-table__draggable__item'>
                                    <AppointmentManagementCard
                                        v-if='breakEventData.card'
                                        :style='{ height: breakEventData.height }'
                                        :data='breakEventData'
                                        :danger='breakEventData.danger'
                                        :color='workerColor'>
                                    </AppointmentManagementCard>
                                </div>
                                <div ref='draggableArea'
                                     class='b-app-management-table__draggable__area'>
                                    <drag v-for='data in eventsDataServer'
                                          :key='data.time'
                                          class='drag h-width-100p'
                                          :class='{ "can-drag": canDragCard(data) }'
                                          tag='div'
                                          :style='calculateFullPosition(data)'
                                          :draggable='canDragCard(data)'
                                          :transfer-data='{ card: data }'
                                          @mouseover.native.stop='forceHideAddZone = true'
                                          @mouseleave.native.stop='forceHideAddZone = false'
                                          @drag='data => onDrag(data)'
                                          @dragstart='onDragStart'
                                          @dragend='onDragEnd'>
                                        <div :id='data.time'
                                             :class='{
                                                 "b-drag-forbidden": data.id && !$options.AVAILABLE_FOR_DRAG.includes(data.id) || !canDrag,
                                                 "b-drag-empty": !data.id,
                                                 "b-drag-available": canDrag && $options.AVAILABLE_FOR_DRAG.includes(data.id),
                                             }'
                                             class='b-app-management-table__draggable__item'>
                                            <AppointmentManagementCard
                                                v-if='data.card'
                                                v-show='isCardShow(data)'
                                                :style='{ height: data.height }'
                                                :data='data'
                                                :danger='data.danger'
                                                :color='workerColor'>
                                            </AppointmentManagementCard>
                                        </div>
                                    </drag>
                                </div>
                                <template v-if='currentAvailableSlots.length > 0 && draggedCard && !isAltDrag'>
                                    <drop
                                        v-for='drop in currentAvailableSlots'
                                        :key='drop.dt_start'
                                        :class='{ over: driveOver && drop.id === driveOver.id }'
                                        class='b-drop-area'
                                        tag='div'
                                        :style='getDropStyles(drop)'
                                        @dragover='({ card }, event) => onDragOver(drop, event, card)'
                                        @dragleave='onDragLeave'
                                        @drop='card => handleDrop(card)'>
                                    </drop>
                                </template>
                            </div>
                        </div>
                        <div class='h-flex-center h-mt-15'>
                            <FwButton
                                size='medium'
                                borderRadiusType='tiny-border'
                                class='b-appointment-management-popup__submit'
                                :disabled='isMainSubmitDisabled'
                                :class='{ "b-appointment-management-popup__submit--disabled": isMainSubmitDisabled }'
                                @click='doSubmit'>
                                {{ $t('BUTTON.NEXT') }}
                            </FwButton>
                        </div>
                    </div>
                    <div v-if='hasAltSlotTtoShow'
                         class='b-app-management-table__inner'>
                        <div class='b-app-management-table__title b-app-management-table__title--success'>
                            {{ $t('APPOINTMENT.MANAGEMENT.OPTION_SUGGESTED') }}
                        </div>
                        <div class='b-app-management-table'
                             :class='{ "b-app-management-table--dragged": !!draggedCard }'>
                            <div class='b-app-management-table__draggable__border'
                                 :style='workingTimeBorderStyles'>
                            </div>
                            <div class='b-app-management-table__time__wrapper'>
                                <div v-for='(data, timeIndex) in tableTimeAlt'
                                     :key='`${data.time}_${timeIndex}`'
                                     class='b-app-management-table__time'>
                                    {{ data.calendarViewTime }}
                                </div>
                            </div>
                            <div class='h-width-100p h-pos-rel'>
                                <div v-if='breakEventData'
                                     :style='calculateFullPosition(breakEventData)'
                                     :class='{
                                         "b-drag-forbidden": false,
                                         "b-drag-available": false,
                                     }'
                                     class='b-app-management-table__draggable__item'>
                                    <AppointmentManagementCard
                                        v-if='breakEventData.card'
                                        :style='{ height: breakEventData.height }'
                                        :data='breakEventData'
                                        :danger='breakEventData.danger'
                                        :color='workerColor'>
                                    </AppointmentManagementCard>
                                </div>
                                <div ref='draggableArea'
                                     class='b-app-management-table__draggable__area'>
                                    <drag v-for='data in eventsDataServerAlt'
                                          :key='data.time'
                                          class='drag h-width-100p can-not-drag'
                                          :class='{ "can-drag": canDragCard(data) }'
                                          tag='div'
                                          :style='calculateFullPosition(data)'
                                          :draggable='canDragCard(data)'
                                          :transfer-data='{ card: data }'
                                          @mouseover.native.stop='forceHideAddZone = true'
                                          @mouseleave.native.stop='forceHideAddZone = false'
                                          @drag='data => onDrag(data, true)'
                                          @dragstart='onDragStart'
                                          @dragend='onDragEnd'>
                                        <div :id='data.time'
                                             :class='{
                                                 "b-drag-forbidden": true,
                                                 "b-drag-empty": !data.id,
                                                 "b-drag-available": false,
                                             }'
                                             class='b-app-management-table__draggable__item'>
                                            <AppointmentManagementCard
                                                v-if='data.card'
                                                v-show='isCardShow(data)'
                                                :style='{ height: data.height }'
                                                :data='data'
                                                :color='workerColor'>
                                            </AppointmentManagementCard>
                                        </div>
                                    </drag>
                                </div>
                                <template v-if='currentAvailableSlotsAlt.length > 0 && draggedCard && isAltDrag'>
                                    <drop
                                        v-for='drop in currentAvailableSlotsAlt'
                                        :key='drop.dt_start'
                                        :class='{ over: driveOver && drop.id === driveOver.id }'
                                        class='b-drop-area'
                                        tag='div'
                                        :style='getDropStyles(drop)'
                                        @dragover='({ card }, event) => onDragOver(drop, event, card)'
                                        @dragleave='onDragLeave'
                                        @drop='card => handleDrop(card, true)'>
                                    </drop>
                                </template>
                            </div>
                        </div>
                        <div class='h-flex-center h-mt-15'>
                            <FwButton
                                size='medium'
                                borderRadiusType='tiny-border'
                                class='b-appointment-management-popup__submit'
                                :class='{ "b-appointment-management-popup__submit--disabled": isMainAltSubmitDisabled }'
                                :disabled='isMainAltSubmitDisabled'
                                @click='doSubmit({ isAltSlot: true })'>
                                {{ $t('BUTTON.NEXT') }}
                            </FwButton>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </FwPopup>
</div>
</template>

<script lang='ts'>
import { namespace, State } from 'vuex-class';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import { clone, pick } from 'ramda';
import en from 'dayjs/locale/en';
import dayjs from 'dayjs';
import { Drag, Drop } from 'vue-drag-drop';
import { TranslateResult } from 'vue-i18n';
import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { insertIf } from '@/helpers/global';
import { formatDateForTitle, BASE_BACK_TIME_FORMAT } from '@/helpers/dates';
import { WorkerAvatar } from '@/components/nodes/WorkerAvatar';
import { EventDataType } from '@/types/Events';
import { mainCalendarTime, time } from '@/mocks/tableData';
import { AddAnAppointment } from '@/components/simple/AddAnAppointment';
import { ConsultCalendarEventCard } from '@/components/simple/ConsultCalendarEventCard';
import { AppointmentManagementCard } from '@/components/simple/AppointmentManagementCard';
import { BookingEventType, EventsDataType } from '@/types/Availabilities';
import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';
import {
    DriveInformationFullType,
    DriveInformationInstanceType,
    DriveInformationScheduleType,
    DriveInformationSlotType,
    DriveInformationUpdatePayload, DriveSlotType,
    DriveSlotTypeFull,
} from '@/types/Appointment';
import {
    BREAK_ID,
    DRIVE_FROM_ID,
    DRIVE_TO_ID,
    MAIN_SLOT_ID,
    AVAILABLE_FOR_DRAG,
    LUNCH_BREAK_ID,
    TIME_MANAGEMENT_REQUIRED_KEYS,
} from '@/components/popups/AppointmentManagementPopup/helpers';
import { UserListType } from '@/types/User';

type FullEventType = BookingEventType & { duration: number, top: number, height: number, dragging: boolean, newIndex: number }
type CardEventType = EventsDataType | EventDataType;

const BACK_END_FORMAT = 'ddd, DD MMM YYYY HH:mm:ss';

const AppointmentStore = namespace('AppointmentStore');
const firstWorkingCalendarHour = 7;

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

@Component({
    AVAILABLE_FOR_DRAG,
    components: {
        Drag,
        Drop,
        AddAnAppointment,
        WorkerAvatar,
        AppointmentManagementCard,
    },
})
export default class AppointmentManagementPopup extends Vue {
    @AppointmentStore.Mutation('updateViewedSlotsList') updateViewedSlotsList!: (id: string) => void;

    @Prop({ type: String, required: true }) readonly dt!: string;
    @Prop({ type: String, required: true }) readonly appointmentId!: string;
    @Prop({ type: String, required: true }) readonly appointmentTitle!: string;
    @Prop({ type: Object, required: true }) readonly advisor!: UserListType;

    isPostLoading: boolean = false;
    isAltDrag: boolean = false;
    date: Date = new Date();
    timeData: Array<EventDataType> = clone(mainCalendarTime);
    isLoading: boolean = false;
    infoData: null | DriveInformationFullType = null

    layerY: number | null = null;
    draggedCard: null | EventsDataType = null;
    driveForUpdate: null | EventsDataType = null;
    driveUpdateTo: null | string = null;
    forceHideAddZone: boolean = false;
    driveOver: null | DriveSlotTypeFull = null;
    driveAvailabilities: Array<DriveSlotTypeFull> = [];

    get isMainAltSubmitDisabled(): boolean {
        if (this.isCurrentUser) {
            return !(
                this.infoData &&
                this.infoData.alternative_slot &&
                this.infoData.alternative_slot.is_valid
            );
        }
        return !(
            this.infoData &&
            this.infoData.alternative_slot &&
            this.infoData.alternative_slot.drive_from_valid &&
            this.infoData.alternative_slot.drive_to_valid &&
            this.infoData.alternative_slot.is_valid
        );
    }

    get isMainSubmitDisabled(): boolean {
        if (this.isCurrentUser) {
            const driveFrom = this.eventsDataServer.find(item => item.id === DRIVE_FROM_ID);
            const driveTo = this.eventsDataServer.find(item => item.id === DRIVE_TO_ID);
            return !(
                this.infoData &&
                this.infoData.slot &&
                this.infoData.slot.is_valid &&
                (driveFrom?.alt || driveFrom?.success) &&
                (driveTo?.alt || driveTo?.success)
            );
        }
        return !(
            this.infoData &&
            this.infoData.slot &&
            this.infoData.slot.drive_from_valid &&
            this.infoData.slot.drive_to_valid &&
            this.infoData.slot.is_valid
        );
    }

    get hasAltSlotTtoShow(): boolean {
        return !!(this.infoData && this.infoData.alternative_slot);
        // return this.infoData.alternative_slot && !this.canDrag;
    }

    get isCurrentUser(): boolean {
        return this.advisor.id === this.user().id;
    }

    get canDrag(): boolean {
        return true || this.isCurrentUser;
    }

    get workerColor(): string | null {
        return this.advisor.color ? this.advisor.color.split(' - ')[0] : null;
    }

    get firstCalendarHour(): number {
        return this.fullTimeFrozen[0].hours || firstWorkingCalendarHour;
    }

    get eventsDataServer(): Array<EventsDataType> {
        if (!this.infoData) return [];
        const { slot } = this.infoData;
        const { working_schedule } = this.infoData;
        const { instances } = this.infoData;
        return this.calculateEventsData({ slot, working_schedule, instances });
    }

    get eventsDataServerAlt(): Array<EventsDataType> {
        if (!this.infoData || !this.infoData.alternative_slot) return [];
        const { alternative_slot } = this.infoData;
        const { working_schedule } = this.infoData;
        const { instances } = this.infoData;
        return this.calculateEventsData({ slot: alternative_slot, working_schedule, instances });
    }

    get workingTimeBorderStyles(): { [key: string]: string } {
        return {
            top: `${(this.workingStartEndDate.start - this.firstCalendarHour) * 60 - 1}px`,
            height: `${(this.workingStartEndDate.end - this.workingStartEndDate.start) * 60 + 1}px`,
        };
    }

    get workingStartEndDate(): { start: number, end: number } {
        const defaultDtStart = firstWorkingCalendarHour;
        const defaultDtEnd = mainCalendarTime[mainCalendarTime.length - 1].hours;
        if (!this.infoData || !this.infoData.working_schedule.start && !this.infoData.working_schedule.end) {
            return {
                start: defaultDtStart,
                end: defaultDtEnd,
            };
        }
        const { working_schedule } = this.infoData;
        let eventStartTime = defaultDtStart;
        let eventEndTime = defaultDtEnd;
        if (working_schedule.start) {
            const parsedStart = working_schedule.start.split(':');
            eventStartTime = parseInt(parsedStart[0], 10) + parseInt(parsedStart[1], 10) / 60;
        }
        if (working_schedule.end) {
            const parsedEnd = working_schedule.end.split(':');
            eventEndTime = parseInt(parsedEnd[0], 10) + parseInt(parsedEnd[1], 10) / 60;
        }
        return {
            start: eventStartTime,
            end: eventEndTime,
        };
    }

    get startEndDate(): { start: number, end: number } {
        const { start, end } = this.workingStartEndDate;
        const minEventHour = Math.min(...this.eventsDataServer.map(item => item.start.hours));
        const maxEventHour = Math.max(...this.eventsDataServer.map(item => item.end.hours));
        return {
            start: Math.floor(start > minEventHour ? minEventHour : start),
            end: Math.ceil(maxEventHour > end ? maxEventHour : end),
        };
    }

    get breakEventData(): EventsDataType | null {
        return this.eventsDataServer.find(item => item.origin === LUNCH_BREAK_ID) || null;
    }

    get tableTimeAlt() {
        return clone(this.fullTimeFrozen).filter(item => item.main);
    }

    get tableTime(): Array<EventDataType> {
        if (this.eventsDataServer && this.eventsDataServer.length) {
            const firstHourStart = Math.min(...this.eventsDataServer.map(item => dayjs(item.slot).hour()));
            const lastHourStart = Math.max(...this.eventsDataServer.map(item => dayjs(item.slot_end).hour()));
            const data = clone(this.fullTimeFrozen).filter(item => item.main);
            const allTime = clone(time).filter(item => item.main);
            const minHour = firstHourStart < data[0].hours ? firstHourStart : data[0].hours;
            const maxHour = lastHourStart > data[data.length - 1].hours ? lastHourStart : data[data.length - 1].hours;
            const min = allTime.findIndex(item => item.main && item.hours === minHour);
            const max = allTime.findIndex(item => item.main && item.hours === maxHour);
            // const firstIndex = allTime[min - 1] ? min - 1 : min;
            const lastIndex = allTime[max + 1] ? max + 1 : max;
            return allTime.slice(min, lastIndex);
        }
        return clone(this.fullTimeFrozen).filter(item => item.main);
    }

    get mainTime(): Array<EventDataType> {
        return clone(this.fullTimeFrozen).filter(item => item.main);
    }

    get title(): str {
        return formatDateForTitle(new Date(this.dt));
    }

    getDriveAvlbs(drives?: Array<DriveSlotType>) {
        if (!drives || drives.length === 0) {
            return [];
        }
        return drives.map(item => ({
            ...item,
            id: `${item.dt_start}_${item.dt_end}`,
            height: dayjs(item.dt_end).diff(dayjs(item.dt_start), 'minutes'),
        }));
    }

    get currentAvailableSlots(): Array<DriveSlotTypeFull> {
        if (this.draggedCard && AVAILABLE_FOR_DRAG.includes(this.draggedCard.id) && this.infoData) {
            return this.draggedCard.id === DRIVE_TO_ID ? this.getDriveAvlbs(this.infoData.drive_to_avlbs) : this.getDriveAvlbs(this.infoData.drive_from_avlbs);
        }
        return [];
    }

    get currentAvailableSlotsAlt(): Array<DriveSlotTypeFull> {
        if (this.draggedCard && AVAILABLE_FOR_DRAG.includes(this.draggedCard.id) && this.infoData) {
            return this.draggedCard.id === DRIVE_TO_ID ? this.getDriveAvlbs(this.infoData.alt_drive_to_avlbs) : this.getDriveAvlbs(this.infoData.alt_drive_from_avlbs);
        }
        return [];
    }

    get draggedCardHeight(): number {
        if (!this.draggedCard) {
            return 0;
        }
        return dayjs(this.draggedCard.slot_end).diff(dayjs(this.draggedCard.slot), 'minutes');
    }

    get calculateFullTime(): Array<EventDataType> {
        if (!this.infoData || !this.infoData.working_schedule) {
            return clone(mainCalendarTime);
        }
        return clone(time).slice(
            time.findIndex(item => item.hours === this.startEndDate.start - 1),
            time.findIndex(item => item.hours === this.startEndDate.end + 1)
        );
    }

    get fullTime(): Array<EventDataType> {
        if (this.eventsDataServer && this.eventsDataServer.length) {
            return this.calculateFullTime;
        }
        return clone(this.timeData);
    }

    get fullTimeAlt(): Array<EventDataType> {
        // TODO
        if (this.eventsDataServerAlt) {
            return clone(this.calculateFullTime);
        }
        return clone(this.calculateFullTime);
    }

    get fullTimeFrozen(): Array<EventDataType> {
        if (!this.eventsDataServer.length) {
            return clone(this.timeData);
        }
        // @ts-ignore
        return Object.freeze(clone(this.calculateFullTime));
    }

    getUpdateDriveInformationPayload({ isAltSlot = false }: { isAltSlot?: boolean }): DriveInformationUpdatePayload {
        const data = this.infoData?.alternative_slot;
        const extraParams = {
            appointment_id: this.appointmentId,
            advisor_id: (this.advisor.id as string),
        };
        if (isAltSlot && data) {
            return {
                ...pick(TIME_MANAGEMENT_REQUIRED_KEYS, data),
                ...extraParams,
            };
        }
        const driveFrom = this.fullTime.find(item => item.id === DRIVE_FROM_ID);
        const driveTo = this.fullTime.find(item => item.id === DRIVE_TO_ID);
        // @ts-ignore
        return {
            // @ts-ignore-next-line
            drive_to_start: driveTo ? driveTo.slot : this.infoData?.slot.drive_to_start,
            // @ts-ignore-next-line
            drive_to_end: driveTo ? driveTo.slot_end : this.infoData?.slot.drive_to_end,
            // @ts-ignore-next-line
            drive_from_start: driveFrom ? driveFrom.slot : this.infoData?.slot.drive_from_start,
            // @ts-ignore-next-line
            drive_from_end: driveFrom ? driveFrom.slot_end : this.infoData?.slot.drive_from_end,
            ...pick(['slot_start', 'slot_end'], this.infoData?.slot),
            ...extraParams,
        };
    }

    isCardShow(data: EventDataType): boolean {
        // @ts-ignore-next-line
        if (AVAILABLE_FOR_DRAG.includes(data.id)) {
            // @ts-ignore-next-line
            return data.slot_end !== data.slot;
        }
        return true;
    }

    async doSubmit({ isAltSlot = false }: { isAltSlot?: boolean }): Promise<void> {
        if ((isAltSlot && this.isMainAltSubmitDisabled) || (!isAltSlot && this.isMainSubmitDisabled)) {
            return;
        }
        const payload: DriveInformationUpdatePayload = this.getUpdateDriveInformationPayload({ isAltSlot });
        try {
            this.isPostLoading = true;
            this.submitHandler(payload);
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isPostLoading = false;
        }
    }

    async onDragStart({ card }: { card: EventsDataType }) {
        // this.driveAvailabilities = this.calculateAvailableSlots(card);
    }

    async onDragEnd() {
        this.draggedCard = null;
    }

    onDrag({ card }: { card: EventsDataType }, isAlt: boolean) {
        this.draggedCard = card;
        this.isAltDrag = !!isAlt;
    }

    getDropStyles(drop: DriveSlotTypeFull) {
        let height = dayjs(drop.dt_end).diff(dayjs(drop.dt_start), 'minutes');
        const color = `#DFFAF5`;
        const dropMainColor = `#27dbbd`;
        let top: string | number = 0;
        const isBefore = dayjs(drop.dt_start).hour() < this.firstCalendarHour;
        const firstHourDate = dayjs(drop.dt_start).hour(this.firstCalendarHour).minute(0);

        if (isBefore) {
            const diff = this.draggedCard ? dayjs(drop.dt_end).diff(firstHourDate, 'minutes') : 60;
            top = diff - this.draggedCardHeight;
            height = this.draggedCardHeight;
        } else {
            top = dayjs(drop.dt_start).diff(firstHourDate, 'minute');
        }
        const topLine = (this.layerY || 0);
        const hoverCurrentCard = typeof this.layerY === 'number' && this.driveOver && drop.id === this.driveOver.id;
        let background = '#fff';
        if (isBefore && hoverCurrentCard) {
            background = dropMainColor;
        } else {
            background = typeof this.layerY === 'number' && this.driveOver && drop.id === this.driveOver.id ? `linear-gradient(to bottom,
                    ${color} ${topLine}px,
                    ${dropMainColor} ${topLine}px,
                    ${dropMainColor} ${topLine + this.draggedCardHeight}px,
                    ${color} ${topLine + this.draggedCardHeight}px,
                    ${color} ${height}px
                )` : color;
        }
        return {
            position: 'absolute',
            height: `${height}px`,
            top: typeof top === `number` ? `${top}px` : top,
            width: '100%',
            background,
            border: `1px solid #fff`,
            zIndex: `1`,
        };
    }

    onDragOver(drop: DriveSlotTypeFull, event: DragEvent, card: EventsDataType) {
        // @ts-ignore-next-line
        const value = Math.floor(event.layerY / 15) * 15;
        const height = dayjs(drop.dt_end).diff(dayjs(drop.dt_start), 'minutes');
        if (value === this.layerY) return;
        this.layerY = value + card.height > height ? height : value;
        this.driveOver = drop;
    }

    onDragLeave() {
        this.driveOver = null;
        this.layerY = null;
    }

    handleDrop({ card }: { card: EventsDataType }, isAlt: boolean) {
        if (this.driveOver && typeof this.layerY === 'number') {
            const minutes = dayjs(this.driveOver.dt_start).minute();
            const hours = dayjs(this.driveOver.dt_start).hour();
            let driveUpdate;
            if (dayjs(this.driveOver.dt_start).hour() < this.firstCalendarHour) {
                driveUpdate = dayjs(this.driveOver.dt_end).add(-this.draggedCardHeight, 'minutes');
            } else {
                driveUpdate = dayjs(this.driveOver.dt_start)
                    .hour(hours + Math.floor(this.layerY / 60))
                    .minute(minutes + this.layerY % 60);
            }
            const driveUpdateTo = driveUpdate.locale('en').format(BASE_BACK_TIME_FORMAT);
            if (card.slot === driveUpdateTo) {
                return;
            }
            const driveUpdateEnd = driveUpdate.add(Math.floor(card.height / 60), 'hour').add(card.height % 60, 'minute');
            const driveUpdateEndTo = driveUpdateEnd.locale('en').format(BASE_BACK_TIME_FORMAT);
            const isValidDataFrom = driveUpdate.isAfter(dayjs(this.driveOver.dt_start)) || driveUpdate.isSame(dayjs(this.driveOver.dt_start), 'minute');
            const isValidDataTo = driveUpdateEnd.isBefore(dayjs(this.driveOver.dt_end)) || driveUpdateEnd.isSame(dayjs(this.driveOver.dt_end), 'minute');
            if (!isValidDataFrom || !isValidDataTo) {
                return;
            }
            const isBefore = !driveUpdateEnd.isAfter(dayjs(driveUpdateTo).hour(
                this.fullTimeFrozen[this.fullTimeFrozen.length - 1].hours + 1
            ).minute(0));
            const slotType = isAlt ? 'alternative_slot' : 'slot';
            // @ts-ignore-next-line
            const slotToUnpack = this.infoData[slotType];
            // @ts-ignore-next-line
            this.infoData = {
                ...this.infoData,
                [slotType]: {
                    ...slotToUnpack,
                    ...(card.id === DRIVE_TO_ID && {
                        drive_to_start: driveUpdateTo,
                        drive_to_end: driveUpdateEndTo,
                        drive_to_valid: true,
                    }),
                    ...(card.id === DRIVE_FROM_ID && {
                        drive_from_start: driveUpdateTo,
                        drive_from_end: driveUpdateEndTo,
                        drive_from_valid: true,
                    }),
                },
            };
        }
        this.draggedCard = null;
        this.driveOver = null;
        this.layerY = null;
    }

    canDragCard(data: CardEventType): boolean {
        return !!('id' in data && data.id && AVAILABLE_FOR_DRAG.includes(data.id));
    }

    detectIsDriveAlt(
        events: Array<EventsDataType>,
        { from, to }: { from: string, to: string }
    ): boolean {
        if (this.isCurrentUser) {
            const fromDate = dayjs(from);
            const toDate = dayjs(to);
            return events.some(event => {
                const eventStart = dayjs(event.slot);
                const eventEnd = dayjs(event.slot_end);
                return (
                    (fromDate.isSameOrAfter(eventStart) && fromDate.isBefore(eventEnd)) || // 'from' is in the event range
                    (toDate.isAfter(eventStart) && toDate.isSameOrBefore(eventEnd)) || // 'to' is in the event range
                    (fromDate.isSameOrBefore(eventStart) && toDate.isSameOrAfter(eventEnd)) // Event is within { from, to }
                );
            });
        }
        return false;
    }

    calculateEventsData(
        {
            slot,
            working_schedule,
            instances,
        }: {
            slot: DriveInformationSlotType | null,
            instances: Array<DriveInformationInstanceType>,
            working_schedule: DriveInformationScheduleType,
        }
    ) {
        if (!slot) {
            return [];
        }
        const events = instances.length ? [...instances.map((instance, index) => {
            const [hourStart, minutesStart] = instance.time_start.split(':');
            const [hourEnd, minutesEnd] = instance.time_end.split(':');
            return this.prepareCardData({
                id: `${instance.time_end}_index`,
                start: dayjs(slot.slot_start)
                    .set('hour', parseInt(hourStart, 10))
                    .set('minute', parseInt(minutesStart, 10))
                    .locale('en')
                    .format(BACK_END_FORMAT),
                end: dayjs(slot.slot_start)
                    .set('hour', parseInt(hourEnd, 10))
                    .set('minute', parseInt(minutesEnd, 10))
                    .locale('en')
                    .format(BACK_END_FORMAT),
                origin: instance.origin,
                title: instance.origin,
                notSortable: true,
            });
        })] : [];
        return [
            this.prepareCardData({
                id: DRIVE_FROM_ID,
                start: slot.drive_from_start,
                end: slot.drive_from_end,
                danger: !slot.drive_from_valid,
                alt: this.isCurrentUser && !slot.drive_from_valid && !this.detectIsDriveAlt(events, {
                    from: slot.drive_from_start,
                    to: slot.drive_from_end,
                }),
                success: slot.drive_from_valid,
            }),
            this.prepareCardData({
                id: DRIVE_TO_ID,
                start: slot.drive_to_start,
                end: slot.drive_to_end,
                danger: !slot.drive_to_valid,
                alt: this.isCurrentUser && !slot.drive_to_valid && !this.detectIsDriveAlt(events, {
                    from: slot.drive_to_start,
                    to: slot.drive_to_end,
                }),
                success: slot.drive_to_valid,
            }),
            this.prepareCardData({
                id: MAIN_SLOT_ID,
                start: slot.slot_start,
                end: slot.slot_end,
                danger: !slot.is_valid,
                title: this.appointmentTitle,
                success: slot.is_valid,
            }),
            ...insertIf(!!(working_schedule.break_end && working_schedule.break_start), ...[BREAK_ID].map(id => {
                // @ts-ignore
                const [hourStart, minutesStart] = working_schedule.break_start ? working_schedule.break_start.split(':') : '';
                // @ts-ignore
                const [hourEnd, minutesEnd] = working_schedule.break_end ? working_schedule.break_end.split(':') : '';
                return this.prepareCardData({
                    id,
                    start: dayjs(slot.slot_start)
                        .set('hour', parseInt(hourStart, 10))
                        .set('minute', parseInt(minutesStart, 10))
                        .locale('en')
                        .format(BACK_END_FORMAT),
                    end: dayjs(slot.slot_start)
                        .set('hour', parseInt(hourEnd, 10))
                        .set('minute', parseInt(minutesEnd, 10))
                        .locale('en')
                        .format(BACK_END_FORMAT),
                    origin: LUNCH_BREAK_ID,
                    title: this.$t('APPOINTMENT.MANAGEMENT.LUNCH_BREAK'),
                });
            })),
            ...insertIf(!!(events.length), ...events),
        ];
    }

    prepareCardData(
        {
            start,
            end,
            id,
            origin,
            title,
            danger,
            alt,
            notSortable,
            success = false,
        }: {
            start: string,
            end: string,
            id: string,
            origin?: string,
            title?: str,
            danger?: boolean,
            alt?: boolean,
            notSortable?: true
            success?: boolean
        }
    ): EventsDataType {
        return {
            id,
            slot: start,
            slot_end: end,
            hours: dayjs(start).hour(),
            minutes: dayjs(start).minute(),
            start: {
                hours: dayjs(start).hour(),
                minutes: dayjs(start).minute(),
            },
            end: {
                hours: dayjs(end).hour(),
                minutes: dayjs(end).minute(),
            },
            frozen: id === MAIN_SLOT_ID || (notSortable || false),
            notSortable: !!notSortable,
            draggingBanned: !AVAILABLE_FOR_DRAG.includes(id),
            height: dayjs(end).diff(dayjs(start), 'minute'),
            card: true,
            origin: origin || '',
            title: title || '',
            danger,
            alt,
            success,
        };
    }

    getZIndex(data: EventsDataType) {
        if (data.id === MAIN_SLOT_ID) {
            return 4;
        }
        return data.origin === LUNCH_BREAK_ID || !this.canDragCard(data) ? 1 : 2;
    }

    calculateFullPosition(data: EventsDataType) {
        return data.height ? {
            position: 'absolute',
            top: `${(data.start.hours - this.firstCalendarHour) * 60 + data.start.minutes}px`,
            height: `${data.height - 1}px`,
            marginTop: `1px`,
            zIndex: this.getZIndex(data),
        } : null;
    }

    getTop(child: HTMLElement, parent: HTMLElement) {
        const childRect = child.getBoundingClientRect();
        const parentRect = parent.getBoundingClientRect();
        return childRect.top - parentRect.top - 2;
    }

    @Emit('submit')
    submitHandler(payload: DriveInformationUpdatePayload): DriveInformationUpdatePayload {
        return payload;
    }

    async created(): Promise<void> {
        try {
            this.isLoading = true;
            this.infoData = await AppointmentWebApi.getDriveInformation({
                dt: this.dt,
                appointment_id: this.appointmentId,
                // @ts-ignore
                advisor_id: this.advisor.id,
            });
            this.updateViewedSlotsList(`${this.dt}_${this.advisor.id}`);
            if (!this.infoData.slot) {
                this.sentNotif(this.$t('APPOINTMENT.MANAGEMENT.DRIVE_CANT_BUILD'), false);
                this.$emit('close');
            }
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.isLoading = false;
        }
    }
}
</script>
<style lang='sass'>
$left-td-width: 65px
$left-td-width-mobile: 45px
$line-height: 60px
$border-top: 1px solid #d5dae3

.b-appointment-management-popup
    &__worker
        display: flex
        align-items: center
        justify-content: center
        font-size: 14px
        font-weight: 500

        .b-worker__avatar
            margin-right: 5px

        .b-worker__info
            flex: 0

    .b-worker__avatar__icon
        margin-top: 3px

    &__tables__wrapper
        display: flex

.b-app-management-table
    padding: 0
    margin: 15px 10px 0
    display: flex
    background-color: #f3f4f7
    max-height: 80vh
    position: relative
    overflow-y: auto
    overflow-x: hidden

    @media (max-height: 1000px)
        max-height: calc(100vh - 220px)

    @include media('<=phone')
        margin: 15px 4px 0
        max-height: 64vh

    &__title
        height: 34px
        display: flex
        align-items: center
        justify-content: center
        background-color: #F8F9FA
        color: #213F6B
        font-weight: 500
        margin: 10px 10px 0
        border-radius: 5px
        text-transform: uppercase

        @include media('<=phone')
            font-size: 12px

        &--success
            background: #27DBBD1A
            color: #27DBBD

    &__inner
        flex: 1 0

    &__time
        text-align: center
        width: $left-td-width
        min-width: $left-td-width
        padding: 5px 5px 0
        color: #213F6B
        font-size: 12px
        font-weight: 500
        border-right: 1px solid #d5dae3
        height: $line-height
        border-top: $border-top
        position: relative

        &:before
            height: 0px
            border-bottom: 1px solid #d5dae3
            position: absolute
            bottom: -1px
            left: 0
            width: 700px
            content: ''

        @include media('<=phone')
            width: $left-td-width-mobile
            min-width: $left-td-width-mobile

        &--active
            color: $dark-blue
            font-weight: bold

        div
            position: relative
            left: 0px
            height: 54px
            background-color: #f3f4f7
            font-size: 11px
            color: #213F6B
            font-weight: 500
            padding-top: 10px

    &__draggable
        border-top: $border-top
        width: 100%

        &__border
            border: 2px solid #27DBBD
            position: absolute
            width: 100%
            border-radius: 6px
            z-index: 1

        &__area
            position: relative
            border-top: 1px solid #d5dae3

            &--disabled
                .b-app-management-table__draggable__item
                    cursor: not-allowed

        &__item
            width: 100%
            height: 100%
            left: 0px
            width: 100%
            position: relative
            z-index: 2

        &__wrapper
            height: $line-height

.b-drag-forbidden
    cursor: not-allowed !important

.b-drag-empty
    cursor: default

.b-drag-available
    cursor: move
    z-index: 3

.drop.over, .b-drop-area.over
    border-color: #aaa
    background: #ccc
    .drag
        padding-top: 10px
        > .drag
            padding: 10px
            background: rgba(0,0,0,0.3)
            width: auto
            height: auto

.b-drop-area
    background-color: rgba(39,214,189,0.2)
    border: 2px dashed #3bd6bd !important
    width: 100%
    z-index: 5 !important

    &--over
        background-color: red
        width: 100%
        height: 30px
        position: absolute

.b-drag-forbidden, .b-drag-empty
    cursor: default !important

.drop.over, .b-drop-area.over
    border-color: transparent
    background: transparent

.drag
    transition: none !important

    &.can-drag
        cursor: move !important

    &.can-not-drag
        cursor: not-allowed !important
</style>
