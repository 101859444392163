<template>
<component
    :is='canRead ? `router-link` : `div`'
    :to='workerTimeIcon || !canRead ? false : { path: `/consult/${kindWorker}/${worker.id}` }'
    class='b-worker-card__wrapper qa-advisor-card'>
    <FwSimpleCard
        class='b-worker-card'
        width='100%'
        height='auto'
        padding='10px 15px'
        :isActive='canRead'
        :isDisabled='!canRead'>
        <AdvisorAvatar
            :workerTimeIcon='workerTimeIcon'
            :advisor='worker'
            avatarSize='35'
            size='small'
            align='base'>
        </AdvisorAvatar>
    </FwSimpleCard>
</component>
</template>

<script lang='ts'>
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { WorkerType } from '@/types/Workers';
import { ConsultWorkerPage } from '@/views/consult/workers/tech/ConsultWorkerPage';
import { AdvisorAvatar } from '@/components/nodes/AdvisorAvatar';
import PermissionsMixin from '@/mixins/permissions';

@Component({
    components: {
        AdvisorAvatar,
    },
})
export default class WorkerConsultCard extends Mixins(PermissionsMixin) {
    @Prop({ type: Object, default: null }) readonly worker!: WorkerType;
    @Prop({ type: Number, default: null }) readonly workerId!: number;
    @Prop({ type: Boolean, default: false }) readonly workerTimeIcon!: boolean;

    get workerUrl(): { name: string, params: { id: string } } {
        return {
            name: ConsultWorkerPage.name,
            params: {
                id: this.worker.id,
            },
        };
    }

    get isRouteAdvisor() {
        return this.$route.path.includes('advisors');
    }
    get kindWorker() {
        return this.isRouteAdvisor ? 'advisor' : 'technician';
    }
}
</script>

<style lang='sass'>
$worker-avatar-size: 48px
$card-min-height: 70px

.b-worker-card
    width: 100%
    display: flex
    justify-content: space-between
    align-items: center

    &__wrapper
        display: flex
        min-height: $card-min-height
        align-items: center

        @include media('<=phone')
            flex: 1 0 100% !important

        @include media('>phone')
            display: block
            max-width: 249px
            flex: 0 0 50%

            .fw-simple-card-wrapper
                width: 249px
                min-width: 249px

        .fw-simple-card-wrapper
            min-height: $card-min-height

    @include media('<=phone')
        min-height: 110px

        @include media('<=phone')
            flex: 1
    &__tag
        max-width: 200px
        padding: 3px 10px
        border-radius: 5px
        font-size: 14px
        font-weight: 500

        &:not(:last-of-type)
            margin-bottom: 5px

    &__tag--nick
        font-size: 10px
        background-color: #EEFDFA
        color: $main-green

    &__tag--id
        font-size: 10px
        background-color: #F2F4F6
        color: $alt-blue
</style>
