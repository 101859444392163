<template>
<div class='b-add-appointment-type'>
    <FwSpinLoader
        v-if='loading'
        :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='loading'
        className='h-p-20'>
    </FwSpinLoader>
    <template v-else>
        <template v-if='!currentAppointmentKindState && appointmentKinds.length > 1'>
            <div class='h-text-center h-pt-30 h-pos-rel h-ph-15'>
                <GoBackButton
                    v-if='clientApiSupport'
                    class='b-route-wrapper__back_arrow'
                    :method='backToFirstStep'>
                </GoBackButton>
                <h1>
                    {{ $t('SELECT.TYPE.APPOINTMENT.CATEGORY.TITLE') }}
                </h1>
            </div>
            <div class='b-society-selection h-flex-center'>
                <div class='fw-select-base fw-select-white-modern b-classic-width-input'>
                    <FwSelect
                        v-model='currentAppointmentKind'
                        v-focus='appointmentKinds.length'
                        class='fw-select-white qa-select-appointment-kind'
                        :clear-on-select='true'
                        :propsPlaceholder='$t(`SELECT.TYPE.APPOINTMENT.CATEGORY.PLACEHOLDER`)'
                        type='white'
                        :pagination='true'
                        :searchIcon='false'
                        :options='appointmentKinds'
                        :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                        @input='checkCategoriesQuantity'>
                    </FwSelect>
                </div>
            </div>
        </template>
        <template v-else>
            <div class='h-text-center h-pt-30 h-pos-rel h-ph-15'>
                <GoBackButton
                    class='b-route-wrapper__back_arrow'
                    :method='clearAppointmentKind'>
                </GoBackButton>
                <h1>
                    {{ $t('SELECT.TYPE.APPOINTMENT.TITLE') }}
                </h1>
            </div>
            <div class='b-society-selection h-flex-center'>
                <div class='fw-select-base fw-select-white-modern b-classic-width-input'>
                    <FwSelect
                        :key='currentAppointmentKindState'
                        v-model='value'
                        v-focus='!isAppointmentCategoryOne'
                        class='fw-select-white qa-select-appointment-type'
                        :clear-on-select='true'
                        :propsPlaceholder='$t(`SELECT.TYPE.APPOINTMENT.PLACEHOLDER`)'
                        type='white'
                        :pagination='true'
                        :searchIcon='false'
                        :options='sortedAppointmentCategories'
                        :noElementsFoundText='$t(`PLAN.SOCIETY.NO.ELEMENT.FOUND`)'
                        label='title'
                        @input='goToInterventionPage'>
                    </FwSelect>
                </div>
            </div>
        </template>
    </template>
    <portal v-if='isAppointmentCategoryOne' to='appointment-bar-buttons'>
        <FwButton
            class='qa-appointment-set-post-code'
            size='little'
            :disabled='!value'
            borderRadiusType='small-border'
            @click.native='goToInterventionPage(value, isAppointmentCategoryOne)'>
            {{ $t('BUTTON.NEXT') }}
        </FwButton>
    </portal>
</div>
</template>

<script lang='ts'>
import { Component, Mixins } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
    AppointmentCategoryType,
    AppointmentSearchType,
} from '@/types/Appointment';
import { ClientType } from '@/types/User';
import { RouteType } from '@/types/router';

import { AppointmentWebApi } from '@/api/appointment/AppointmentApi';

import { GoBackButton } from '@/components/simple/GoBackButton';

import PermissionsMixin from '@/mixins/permissions';
import AccountMixin from '@/mixins/account';

import { updateUrlPageAndQuery } from '@/helpers/url';

const BreadCrumbsStore = namespace('BreadCrumbsStore');
const AppointmentStore = namespace('AppointmentStore');
const CalendarStore = namespace('CalendarStore');

const PRO_PREFIX = 'PRO';

@Component({
    components: {
        GoBackButton,
    },
})
export default class AddAppointmentTypePage extends Mixins(PermissionsMixin, AccountMixin) {
    @CalendarStore.Mutation('setUserOnCalendar') setUserOnCalendar!: (value: boolean) => void;
    @BreadCrumbsStore.Mutation('setFlowKey') setFlowKey!: (key: string) => void;
    @AppointmentStore.Mutation('setAppointmentCategory') setAppointmentCategory!: (categoryName: AppointmentCategoryType) => void;
    @AppointmentStore.Mutation('clearAppointmentState') clearAppointmentState!: () => void;
    @AppointmentStore.Mutation('setCurrentAppointmentKind') setCurrentAppointmentKind!: (appointmentKind: string) => void;
    @AppointmentStore.Mutation('setAppointmentType') setAppointmentType!: (type: string) => void;
    @CalendarStore.Mutation('setPostCode') setPostCode!: (post_code: string) => void;
    @CalendarStore.Mutation('setCountryIso') setCountryIso!: (country_iso: string) => void;
    @AppointmentStore.State('appointmentDataToUpdate') appointmentDataToUpdate!: AppointmentSearchType | null;
    @AppointmentStore.State('currentAppointmentKindState') currentAppointmentKindState!: string;
    @AppointmentStore.State('caseId') caseId!: string;
    @AppointmentStore.State('opportunityId') opportunityId!: string;
    @AppointmentStore.State('client') client!: ClientType | null;
    @AppointmentStore.State('initialRoute') initialRoute!: RouteType | null;

    value: AppointmentCategoryType | null = null;
    loading: boolean = false;
    currentAppointmentKind: null | string = null;
    appointmentCategories: Array<AppointmentCategoryType> = [];

    get sortedAppointmentCategories() {
        const categories = this.appointmentCategories.filter(item => !this.currentAppointmentKindState || item.kind === this.currentAppointmentKindState);
        const categoriesPro = categories.filter(item => item.title.startsWith(PRO_PREFIX)).sort((a, b) => a.title.localeCompare(b.title));
        const categoriesWithOut = categories.filter(item => !item.title.startsWith(PRO_PREFIX)).sort((a, b) => a.title.localeCompare(b.title));
        return categoriesWithOut.concat(categoriesPro);
    }

    get appointmentKinds() {
        return [...new Set(this.appointmentCategories.map(item => item.kind))].sort((a, b) => a.localeCompare(b));
    }

    get isAppointmentCategoryOne() {
        return this.sortedAppointmentCategories.length === 1;
    }

    clearAppointmentKind() {
        if (this.initialRoute?.path && this.isAlmAccount) {
            this.$router.push(updateUrlPageAndQuery(this.initialRoute.path, 0, this.initialRoute?.query));
        }
        this.setCurrentAppointmentKind('');
        this.value = null;
        this.currentAppointmentKind = null;
        if (this.appointmentDataToUpdate?.id) {
            this.$router.push({ path: `/consult/choose/appointments?id=${this.appointmentDataToUpdate.id}` });
        }
    }

    checkCategoriesQuantity() {
        if (this.currentAppointmentKind) {
            this.setCurrentAppointmentKind(this.currentAppointmentKind);
        }

        if (this.isAppointmentCategoryOne) {
            this.setAppointmentCategory(this.sortedAppointmentCategories[0]);
            this.value = this.sortedAppointmentCategories[0];
        }
    }

    goToInterventionPage(value: AppointmentCategoryType, isButtonActive: boolean) {
        if (this.isAppointmentCategoryOne && !isButtonActive) {
            return;
        }
        this.setAppointmentCategory(value);
        this.$router.push({ path: '/appointment/partners-location' });
    }

    handleKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            this.goToInterventionPage(this.value as AppointmentCategoryType, this.isAppointmentCategoryOne);
        }
    }

    backToFirstStep() {
        if (this.initialRoute?.path) {
            this.$router.push(updateUrlPageAndQuery(this.initialRoute.path, 0, this.initialRoute?.query));
        }
    }

    async created() {
        this.setAppointmentType('');
        document.addEventListener('keyup', this.handleKeyUp);
        if (!this.client) {
            this.clearAppointmentState();
            this.setPostCode('');
            this.setCountryIso('');
        }
        this.setUserOnCalendar(false);
        this.loading = true;
        const data = await AppointmentWebApi.getAppointmentsCategories({
            page: 1,
            per_page: 50,
            ...(this.caseId && { caseValue: true }),
            ...(this.opportunityId && { opportunity: true }),
        });
        this.appointmentCategories = data.appointment_categories;
        this.loading = false;

        if (this.isAppointmentCategoryOne) {
            this.setAppointmentCategory(this.sortedAppointmentCategories[0]);
            this.value = this.sortedAppointmentCategories[0];
        }
    }

    beforeDestroy() {
        document.removeEventListener('keyup', this.handleKeyUp);
    }
}
</script>

<style lang='sass'>
.b-society-selection
    .multiselect__option--highlight
        background: transparent!important
    .multiselect__option--highlight span
        color: rgb(47, 89, 151)!important

    .fw-select-base.fw-select-white-modern, .fw-select-white
        box-shadow: none !important

.b-add-appointment-type
    .multiselect__content-wrapper
        max-height: 350px !important

    .b-route-wrapper__back_arrow
        @include media('<=phone')
            margin-left: 0
</style>
