<template>
<div class='b-management-card'
     :class='{
         "b-management-card--small": data.height <= 15,
         "b-management-card--big": data.height >= 45,
         "b-management-card--danger": data.danger,
         "b-management-card--alt": data.alt,
         "b-management-card--success": data.success,
         "b-management-card--lunch-break": data.origin === $options.LUNCH_BREAK_ID,
         "b-management-card--unavailability": data.origin === $options.UNAVAILABILITY_ORIGIN,
     }'>
    <div class='b-management-card__time-wrapper'>
        <div class='b-management-card__label'
             :style='{
                 borderColor: color
             }'>
        </div>
        <div class='b-management-card__time'>
            <div>
                {{ startDate }}
            </div>

            <div class='b-management-card__title__wrapper'>
                <div class='b-management-card__title'>
                    {{ title }}
                </div>
                <FwImage
                    v-if='$options.AVAILABLE_FOR_DRAG.includes(data.id)'
                    class='b-management-card__icon'
                    height='14px'
                    width='14px'
                    :src='requireImage("car.png")'>
                </FwImage>
            </div>
        </div>
    </div>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import dayjs from 'dayjs';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import DateMixin from '@/mixins/dateMixin';
import { hexToRgbA } from '@/helpers/colors';
import { BranchSitesDataType } from '@/types/Site';
import AccountMixin from '@/mixins/account';
import { EventsDataType } from '@/types/Availabilities';

import {
    AVAILABLE_FOR_DRAG,
    LUNCH_BREAK_ID,
    UNAVAILABILITY_ORIGIN,
} from '@/components/popups/AppointmentManagementPopup/helpers';

const AppointmentStore = namespace('AppointmentStore');

@Component({
    AVAILABLE_FOR_DRAG,
    LUNCH_BREAK_ID,
    UNAVAILABILITY_ORIGIN,
})
export default class AppointmentManagementCard extends Mixins(DateMixin, AccountMixin) {
    @AppointmentStore.State('viewedSlotsList') viewedSlotsList!: Array<string>;

    @Prop({ type: Object, required: true }) readonly data!: EventsDataType;
    @Prop({ type: String, default: '#333' }) readonly color!: string;

    get title(): str {
        if (AVAILABLE_FOR_DRAG.includes(this.data.id)) {
            return this.$t('APPOINTMENT.MANAGEMENT.DRIVE');
        } else if (this.data.title) {
            return this.data.title;
        }
        return '';
    }

    get startDate() {
        return dayjs(this.data.slot).format('HH:mm');
    }
}
</script>

<style lang='sass'>
.b-management-card
    padding: 2px 4px 2px 10px !important
    width: auto
    margin: 0 2px 0 0
    z-index: 1
    transition: transform 0.2s, box-shadow 0.2s
    background: #ccc
    position: relative
    border-radius: 5px
    overflow: hidden
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.07)
    height: 100%

    &--lunch-break
        background: repeating-linear-gradient(140deg, white, white 6px, #e8e9e9 6px, #e8e9e9 16px)

    &--danger
        background: rgba(255, 0, 77, 0.30)

    &--success
        background: rgb(180, 225, 204)

    &--unavailability
        background: rgba(0, 0, 0, 0.25)

    &--alt
        background: rgba(255, 196, 0, 0.44)

    &--big
        padding-top: 10px

        .b-management-card__title__wrapper
            padding-top: 4px

    &--small
        .b-management-card__time
            display: flex
            height: 14px
            align-items: center

            .b-management-card__icon
                margin-left: 5px
                height: 12px !important
                width: 12px !important

        .b-management-card__title__wrapper
            margin-left: 5px

    &__label
        position: absolute
        top: 0
        left: 0
        width: 3px
        height: 100%
        border-left: 3px solid #333
        border: 5px 0 0 5px

    &__outer
        width: 100%

    &__time
        font-size: 12px
        padding-left: 4px

    &__viewed
        position: absolute
        right: 1px
        bottom: 2px
        height: 16px
        width: 10px

    .b-management-card__label
        width: 5px

    &__title
        font-weight: 500

        &__wrapper
            display: flex
</style>
