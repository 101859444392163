export default {
    'WORKER.POPUP.RESERVED.SLOTS.TITLE': 'Reserved slots',
    'WORKER.TIME.SLOT.TITLE': 'Working hours',
    'WORKER.TIME.SLOT.MORNING': 'MORNING',
    'WORKER.TIME.SLOT.DAY': 'DAY',
    'WORKER.TIME.SLOT.AFTERNOON': 'AFTERNOON',
    'WORKER.TIME.SLOT.LUNCH': 'LUNCH BREAK',
    'WORKER.TIME.SLOT.FROM': 'from',
    'WORKER.TIME.SLOT.TO': 'to',
    'WORKER.TIME.CURRENT.TITLE': 'Current',
    'WORKER.TIME.SCHEDULED.TITLE': 'Scheduled',
    'WORKER.TIME.SCHEDULED.UPDATED.AT': 'Will updated at',
    'WORKER.TIME.POPUP.TITLE': 'Save changes',
    'WORKER.TIME.POPUP.TXT': 'Do you want to leave this page without saving?',
    'WORKER.TIME.POPUP.BUTTON.CANCEL': 'Don’t save',
    'WORKER.TIME.POPUP.BUTTON.SAVE': 'Save changes',
    'WORKER.POPUP.ADMIN.TEXT': 'This worker is the admin of this company',
    'WORKER.POPUP.COMPANY.ADMIN': 'This company already has an admin',
    'WORKER.POPUP.CONFIRM.BUTTON': 'I confirm these working hours',
    'WORKER.POPUP.NO.PERMISSION.TEXT': 'You cannot edit working hours after confirmation',
    'WORKER.POPUP.INVITATION.OOPS': 'Oops',
    'WORKER.POPUP.INVITATION.CHECK': 'You need to complete the following tasks before sending an invitation:',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TITLE': 'Confirm the working hours',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.HOURS.TEXT': '(by closing this popup and clicking on \'Working hours\' );',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TITLE': 'Confirm the worker\'s company address',
    'WORKER.POPUP.INVITATION.CHECK.ITEM.ADDRESS.TEXT': '(by clicking on this link).',
    'WORKER.POPUP.INVITATION.CHECK.BUTTON': 'Check and confirm working hours, address',
    'WORKER.POPUP.INVITATION.SEND': 'SEND INVITATION',
    'WORKER.POPUP.INVITATION.SENT': 'Invitation sent',
    'WORKER.POPUP.INVITATION.ACCEPTED': 'Invitation accepted',
    'WORKER.POPUP.INVITATION.RESEND': 'RESEND',
    'WORKER.POPUP.INVITATION.CONFIRMED': 'Confirmed account',
    'WORKER.POPUP.INVITATION.SYNCHRONIZED': 'Synchronized calendar',
    'WORKER.POPUP.INVITATION.OUTLOOK.AUTHORIZED': 'Outlook account linked',
    'WORKER.POPUP.INVITATION.GOOGLE.AUTHORIZED': 'Google account linked',
    'WORKER.POPUP.INVITATION.OUTLOOK.GOOGLE.AUTHORIZED': 'Google and Outlook accounts linked',
    'WORKER.POPUP.INVITATION.SENT.MESSAGE': 'Invitation was successfully sent!',
    'WORKER.NO.WORKER': 'No workers yet',
    'WORKER.UNCONFIRMED': 'Unconfirmed users',
    'WORKER.CONFIRMED': 'Confirmed users',
    'WORKER.EMAIL.TAKEN': 'Email has already been taken',

    'ADVISOR.FORM.DELETE.POPUP.BUTTON': 'Delete this advisor',
    'ADVISOR.FORM.UPDATE.POPUP.TEXT': 'The advisor was updated',
    'ADVISOR.FORM.CREATE.POPUP.TEXT': 'The advisor was created',

    'ADVISOR.SAME.BRANCH.ADDRESS': 'Same as the branch address',

    'TECH.FORM.DELETE.POPUP.BUTTON': 'Delete this technician',
    'TECH.FORM.UPDATE.POPUP.TEXT': 'The technician was updated',
    'TECH.FORM.CREATE.POPUP.TEXT': 'The technician was created',

    'TECH.SAME.COMPANY.ADDRESS': 'Same as the address of the company',

    'UPDATE.ADDRESS.LABEL': 'Update address',
    'ACTIONS.LABEL': 'Actions',
    'SELFCARE.BOOKING.LINK.TITLE': 'Selfcare booking link',
    'SELFCARE.BOOKING.LINK.SUBTITLE': 'Please choose an appointment type and method',
    'SELFCARE.YOUR.BOOKING.LINK.TITLE': 'Your selfcare booking link',
    'SELFCARE.YOUR.BOOKING.LINK.COPY.TO.CLIPBOARD': 'Copied to clipboard',
    'SELFCARE.BOOKING.PLACEHOLDER.TYPE': 'Select an appointment type',
    'SELFCARE.BOOKING.PLACEHOLDER.METHOD': 'Select a method',

    'ADVISOR.GEO_ZONE_SHORT': 'Geo. zone reassignment',
    'ADVISOR.GEO_ZONE_APPT_NOT_REASSIGNED': 'Appointments not reassigned',
    'ADVISOR.GEO_ZONE_REASSIGNMENT.PLURAL': 'Geographic zone reassignment | Geographic zone reassignments',
    'ADVISOR.GEO_ZONE_REASSIGNED_ELEMENTS': 'Reassigned elements',
    'ADVISOR.GEO_ZONE_ELEMENTS': 'element | elements',
    'ADVISOR.GEO_ZONE_REASSIGNED_ZONE': 'Reassigned zone',
    'ADVISOR.GEO_ZONE_RECEIVED_ZONE': 'Received zone',
    'ADVISOR.GEO_ZONE_FAILED_REASSIGNMENTS': 'Failed appointment reassignments',
    'ADVISOR.GEO_ZONE_DISTRICT': 'District/city',
    'ADVISOR.GEO_ZONE_SELECT_ADVISOR': 'Select an advisor',
    'ADVISOR.GEO_ZONE.SEARCH_ADVISOR': 'Search an advisor',
    'ADVISOR.GEO_ZONE.WHEN_REASSIGNMENT_HAPPEN': 'When should this reassignment happen?',
    'ADVISOR.GEO_ZONE.WANT_TO_REASSIGN': 'Do you want to reassign the corresponding appointments?',
    'ADVISOR.GEO_ZONE.START_DATE': 'Start date',
    'ADVISOR.GEO_ZONE.END_DATE': 'End date',
    'ADVISOR.GEO_ZONE.NO_REASSIGNMENT': 'No reassignment',
    'ADVISOR.GEO_ZONE.REASSIGNMENT_DELETE_TITLE': 'Delete reassignment | Delete reassignments',
    'ADVISOR.GEO_ZONE.REASSIGNMENT_DELETE_TEXT': 'Do you really want to delete this reassignment? This action cannot ' +
        'be undone. | Do you really want to delete these reassignments? This action cannot be undone.',
    'ADVISOR.GEO_ZONE.REASSIGNMENT_DELETE_ALL': 'Delete the found reassignments',
    'ADVISOR.GEO_ZONE.REASSIGN_APPOINTMENTS': 'Reassign the corresponding appointments (the reassignment will be effective tomorrow)',

    'ADVISOR.SELFCARE.LINKS.PLURAL': 'Calizy Selfcare links | Calizy Selfcare link',
    'ADVISOR.YOUR.SELFCARE.LINK': 'Your Calizy Selfcare link',
    'ADVISOR.SELFCARE.NO_LINKS': 'No links',
    'ADVISOR.SELFCARE.DELETE.LINK.TITLE': 'Delete link',
    'ADVISOR.SELFCARE.DELETE.LINK.SUBTITLE': 'Do you really want to delete this link? This action cannot be undone.',
};
