<template>
<div class='b-page-wrapper b-calendar-wrapper'
     :class='{ "b-page-wrapper--big-container": calendarSettings.isSettingsActive }'>
    <CalendarHeader
        :calendarDate='calendarDate'
        :hideBackButton='hideCalendarDateNavigationBackButton'
        :hideForwardButton='hideCalendarDateNavigationForwardButton'
        :hideHelpButton='isServicePointPage'
        @changeDay='changeDay'
        @openHotToUsePopup='isHowToUsePopupShow = true'
        @openSettingsPopup='isSettingsPopupShow = true'>
    </CalendarHeader>
    <template>
        <div class='h-pos-rel'>
            <FwSpinLoader
                v-if='isFirstPageLoading || isCellDataLoading'
                :colors='isFirstPageLoading ? ["#27DBBD", "#27DBBD", "#27DBBD"] : ["#fff", "#fff", "#fff"]'
                class='h-flex-center loader'
                :class='{
                    "h-flex-center b-blue-background b-blue-background__opacity b-blue-background--fixed": isCellDataLoading,
                    "b-calendar-wrapper--spinner-with-filters": isFiltersApplied
                }'
                :isActive='isFirstPageLoading || isCellDataLoading'
                className='h-p-20'>
            </FwSpinLoader>
            <CalendarBody
                v-if='showCalendar'
                ref='CalendarBody'
                :users='users'
                :keyField='isServicePointPage ? "id" : "user_id"'
                :filtersLength='filtersLength'
                :daysInMonth='daysInMonth'
                :isAsideInfoShow='calendarSettings.isSettingsActive'
                :asideData='asideData'
                :isColorblind='calendarSettings.isColorblindActive'
                :date='calendarDate'
                :loading='loading'
                @cleanFilters='cleanFilters'
                @infinityLoading='infinityLoading'
                @onCellClick='onCellClick'
                @openFilters='isFiltersOpen = true'>
            </CalendarBody>
        </div>
    </template>
    <div class='b-calendar-wrapper__info-fixed'>
        <RouterLink
            :to='goBackToConsultId'
            class='b-calendar-page__info-link'>
            <CalendarInfo
                v-if='information'
                :informationData='information'>
            </CalendarInfo>
        </RouterLink>
    </div>
    <div v-if='!isDataExists && !isFirstPageLoading && !showAccessLockPage'
         class='b-calendar-wrapper__no-data'>
        {{ $t('CALENDAR.NO_DATA') }}
    </div>
    <FwSpinLoader
        v-if='nextPageLoading'
        :colors='["#27DBBD", "#27DBBD", "#27DBBD"]'
        class='h-flex-center loader'
        :isActive='nextPageLoading'
        className='h-p-20'>
    </FwSpinLoader>
    <div v-if='false'
         class='b-calendar-body__footer__outer'>
        <CalendarFooter
            v-if='!loading && calendarSettings.isSettingsActive'
            :isAsideInfoShow='calendarSettings.isSettingsActive'
            :daysData='daysData'
            :isColorblind='calendarSettings.isColorblindActive'
            :daysInMonth='daysInMonth'>
        </CalendarFooter>
    </div>
    <HowToUsePopup
        v-if='isHowToUsePopupShow'
        :isColorblind='calendarSettings.isColorblindActive'
        @close='isHowToUsePopupShow = false'>
    </HowToUsePopup>
    <SettingsPopup
        v-if='isSettingsPopupShow'
        :calendarSettings='calendarSettings'
        @updateSettings='data => calendarSettings = data'
        @close='isSettingsPopupShow = false'>
    </SettingsPopup>
    <FiltersPopup
        v-if='isFiltersOpen'
        :filtersSettings='filtersSettings'
        @updateFilters='updateFilters'
        @close='isFiltersOpen = false'>
    </FiltersPopup>

    <ChooseBookType
        v-if='isChoosePopupShow'
        :isColorblind='calendarSettings.isColorblindActive'
        :bookData='bookData'
        :details='details'
        @close='isChoosePopupShow = false'>
    </ChooseBookType>
    <ShiftsListPopup
        v-if='showShiftsListPopup'
        :calendarDate='calendarDate'
        :currentSlots='shifts'
        :currentInstancesData='shifts'
        reedOnly
        :isReservedSots='false'
        :recurrenceLoading='false'
        @close='showShiftsListPopup = false'>
    </ShiftsListPopup>
    <AccessLockPage v-if='showAccessLockPage'></AccessLockPage>
</div>
</template>

<script lang='ts'>
import Vue from 'vue';
import dayjs, { Dayjs } from 'dayjs';

import { Component, Mixins } from 'vue-property-decorator';

import { CalendarHeader } from '@/components/calendar/CalendarHeader';
import { CalendarBody } from '@/components/calendar/CalendarBody';
import { CalendarFooter } from '@/components/calendar/CalendarFooter';
import { SettingsPopup } from '@/components/popups/SettingsPopup';
import { FiltersPopup } from '@/components/popups/FiltersPopup';
import { HowToUsePopup } from '@/components/popups/HowToUsePopup';
import { ChooseBookType } from '@/components/popups/ChooseBookType';
import AccessLockPage from '@/views/access/AccessLockPage.vue';
import { ChooseShiftsSlotForm } from '@/components/forms/add/ChooseShiftsSlotForm';

import { sortTypes } from '@/helpers/skills';
import { getAsideDataMock } from '@/components/calendar/helpers';
import { CalendarApi } from '@/api/calendar/CalendarApi';
import {
    CalendarSettingsType,
    FiltersSettingsType,
    CellDataClickType,
    CalendarMonthViewUserDataType,
    CalendarMonthDayDetailsType,
    CalendarViewSettingsResponseType,
    MonthCalendarSPViewItemType,
    MonthCalendarSPAdvisorItemType,
    CalendarMonthDayShiftListType,
} from '@/types/Calendar';
import { BaseMetaType } from '@/types/Response';
import { CONSULT_MONTH_VIEW_CALENDARS } from '@/helpers/calendar';
import { CalendarInfo } from '@/components/calendar/CalendarInfo';

import PermissionsMixin from '@/mixins/permissions';
import { ShiftsListPopup } from '@/components/popups/ShiftsListPopup';

@Component({
    components: {
        ShiftsListPopup,
        ChooseShiftsSlotForm,
        CalendarInfo,
        CalendarHeader,
        CalendarBody,
        CalendarFooter,
        SettingsPopup,
        HowToUsePopup,
        FiltersPopup,
        ChooseBookType,
        AccessLockPage,
    },
})
export default class MonthCalendarPage extends Mixins(PermissionsMixin) {
    calendarDate: Date = new Date();
    loading: boolean = false;
    isSettingsPopupShow: boolean = false;
    isRequestSending: boolean = false;
    isHowToUsePopupShow: boolean = false;
    isCellDataLoading: boolean = false;
    isFiltersOpen: boolean = false;
    showShiftsListPopup: boolean = false;
    isChoosePopupShow: boolean = false;
    users: Array<CalendarMonthViewUserDataType> = [];
    bookData: CellDataClickType | null = null;
    details: CalendarMonthDayDetailsType | null = null;
    meta: BaseMetaType | null = null;
    scrollY: number = 0;
    monthViews: Array<any> = [];
    shifts: Array<CalendarMonthDayShiftListType> = [];
    information: CalendarViewSettingsResponseType | null = null;

    $refs!: {
        CalendarBody: Vue
    };

    calendarSettings: CalendarSettingsType = {
        isColorblindActive: false,
        isSettingsActive: false,
    }

    filtersSettings: FiltersSettingsType = {
        currentSkills: [],
        currentSort: null,
        currentSortType: sortTypes[0],
    }

    get showAccessLockPage(): boolean {
        return this.accessLockMonthPage && !this.loading;
    }

    get goBackToConsultId(): string {
        return `/consult/${this.$route.params.type}/${this.$route.params.id}`;
    }

    get isDataExists(): boolean {
        return !!(this.users && this.users.length) && !this.isFirstPageLoading;
    }

    get nextPageLoading(): boolean {
        return !this.isFirstPageLoading && this.loading;
    }

    get isFirstPageLoading(): boolean {
        return !!(this.loading && !this.meta);
    }

    get showCalendar(): boolean {
        if (this.isFiltersApplied) return true;
        return !this.isFirstPageLoading;
    }

    get filtersLength(): number {
        return this.filtersSettings.currentSkills.length;
    }

    get isFiltersApplied(): boolean {
        return !!this.filtersLength;
    }

    get daysInMonth(): number {
        return dayjs(this.calendarDate).daysInMonth();
    }

    get asideData(): any {
        if (this.loading) return [];
        return this.daysInMonth ? getAsideDataMock(this.users) : [];
    }

    get daysData(): { free: Array<number>, onCall: Array<number> } {
        return {
            free: new Array(this.daysInMonth).fill('').map(() => Math.floor(Math.random() * 10) + 1),
            onCall: new Array(this.daysInMonth).fill('').map(() => Math.floor(Math.random() * 10) + 1),
        };
    }

    get hideCalendarDateNavigationBackButton(): boolean {
        const calendarDate = dayjs(this.calendarDate);
        return dayjs(this.date).diff(calendarDate, 'month') >= 1;
    }

    get hideCalendarDateNavigationForwardButton(): boolean {
        const calendarDate = dayjs(this.calendarDate);
        return calendarDate.diff(this.date, 'month') >= 2;
    }

    get firstDayInMonth(): Dayjs {
        return dayjs(this.calendarDate).set('date', 1);
    }

    get currentSortType(): 'ASC' | 'DESC' {
        if (!this.filtersSettings.currentSortType) return 'ASC';
        return this.filtersSettings.currentSortType.searchable_name === 'ascending' ? 'ASC' : 'DESC';
    }

    get isServicePointPage(): boolean {
        return this.$route.params.type === 'service-point';
    }

    cleanFilters(): void {
        this.updateFilters({
            currentSkills: [],
            currentSort: this.filtersSettings.currentSort,
            currentSortType: this.filtersSettings.currentSortType,
        });
    }

    async updateFilters(data: FiltersSettingsType): Promise<void> {
        this.filtersSettings = data;
        this.meta = null;
        await this.doRequest();
    }

    async onCellClick(cellData: CellDataClickType): Promise<void> {
        this.bookData = cellData;
        if (this.isServicePointPage) {
            if (cellData?.currentDate && typeof cellData?.currentDate?.isValid === 'function') {
                this.calendarDate = new Date(cellData.currentDate.format('YYYY-MM-DD'));
            }
            await this.getMonthViewDayData(cellData);
        } else {
            await this.getViewForUserData();
            this.isChoosePopupShow = true;
        }
    }

    async getMonthViewDayData(cellData: CellDataClickType): Promise<void> {
        if (!this.bookData) return;
        // @ts-ignore
        const currentUserData = this.users.find(item => item.id === this.bookData.user_id)?.dates_break_down;
        if (!currentUserData || !currentUserData[this.bookData.currentDate.format('YYYY-MM-DD')]) return;
        this.isCellDataLoading = true;
        const shifts = await CalendarApi.getMonthViewShiftForDate({
            dt: this.bookData.currentDate.format('YYYY-MM-DD'),
            advisor_id: this.bookData.user_id,
            sp_id: this.$route.params.id,
        });
        this.shifts = shifts.filter(item => item.user_id === cellData.user_id);
        this.isCellDataLoading = false;
        this.showShiftsListPopup = true;
    }

    async infinityLoading(): Promise<void> {
        await this.doRequest();
    }

    async getViewForUserData(): Promise<void> {
        if (!this.bookData) return;
        this.isCellDataLoading = true;
        const data = await CalendarApi.getMonthViewForUser({
            date: this.bookData.currentDate.format('YYYY-MM-DD'),
            user_id: this.bookData.user_id,
            calendar_type: (this.$route.params.type as `hub` | `branch`),
            id: this.$route.params.id,
        });
        this.isCellDataLoading = false;
        this.details = data.details;
    }

    async changeDay({ delta, date }: { delta: number, date: null | Date }): Promise<void> {
        this.loading = true;
        this.meta = null;
        this.calendarDate = dayjs(date).add(delta, 'month').startOf('month').toDate();
        await this.doRequest();
        this.loading = false;
    }

    async fetchCalendarInformation(id: string) {
        if (!CONSULT_MONTH_VIEW_CALENDARS.includes(this.$route.params.type)) return;
        try {
            let url;
            if (this.$route.params.type === `hub`) {
                url = `hubs/${id}/calendar_detail`;
            } else if (this.$route.params.type === `branch`) {
                url = `sites/${id}/calendar_detail`;
            }
            if (url) {
                // @ts-ignore-next-line
                const response = await CalendarApi.getCalendarInformation(url);
                this.information = response.data;
            }
        } catch ({ response }) {
            this.sentNotif(response);
        }
    }

    async updateRow({ user_id }: { user_id: string }): Promise<void> {
        const data = await CalendarApi.getMonthViewRow({ date: this.firstDayInMonth.format('YYYY-MM-DD'), user_id });
        const userIndex = this.users.findIndex(item => item.user_id === data.month_view.user_id);
        if (userIndex !== -1) {
            this.users.splice(userIndex, 1, data.month_view);
        }
    }

    handleScroll(): void {
        this.scrollY = window.scrollY;
        if (this.loading || !this.$refs.CalendarBody || (this.meta && this.meta.next_page <= this.meta.current_page)) return;
        const rect = (this.$refs.CalendarBody.$el as HTMLElement).getBoundingClientRect();
        if (rect.top <= (window.innerHeight || document.documentElement.clientHeight) + window.innerHeight + 1000) {
            this.doRequest();
        }
    }

    updateUsersWithData(data: Array<MonthCalendarSPViewItemType>) {
        this.users.forEach(user => {
            // @ts-ignore-next-line
            const currentUserData = data.find(localUser => localUser.id === user.id);
            if (currentUserData) {
                user.dates_break_down = currentUserData.dates.reduce((acc, current) => ({ ...acc, [current]: 2 }), {});
            }
        });
    }

    async doRequest(): Promise<void> {
        this.loading = true;
        try {
            let data;
            let users: Array<MonthCalendarSPAdvisorItemType> = [];
            if (this.isServicePointPage) {
                users = await CalendarApi.getMonthServicePointUsersView({
                    sp_id: this.$route.params.id,
                });
                data = await CalendarApi.getMonthServicePointView({
                    dt: this.firstDayInMonth.format('ddd, DD MMM YYYY'),
                    sp_id: this.$route.params.id,
                });
            } else {
                data = await CalendarApi.getMonthView({
                    date: this.firstDayInMonth.format('ddd, DD MMM YYYY'),
                    id: this.$route.params.id,
                    calendar_type: (this.$route.params.type as `hub` | `branch`),
                    meta: this.meta,
                    sort: this.currentSortType,
                    skill_ids: this.filtersSettings.currentSkills.map(item => item.id),
                    ...(this.filtersSettings.currentSort && { [this.filtersSettings.currentSort.searchable_name]: true }),
                });
            }
            if (this.isServicePointPage) {
                // @ts-ignore
                this.users = this.nextPageLoading ? this.users.concat(users) : users;
                // @ts-ignore
                this.updateUsersWithData(data);
            } else {
                // @ts-ignore
                this.users = this.nextPageLoading ? this.users.concat(data.month_views) : data.month_views;
            }
            this.meta = `meta` in data ? data.meta : null;
        } catch ({ response }) {
            this.sentNotif(response);
        } finally {
            this.loading = false;
        }
    }

    async doFakeRequest() {
        this.loading = true;
        try {
            const data = await CalendarApi.getFakeMonthData();
            this.users = this.nextPageLoading ? this.users.concat(data.month_views) : data.month_views;
            this.meta = data.meta;
        } catch (e) {
            console.log(e);
        } finally {
            this.loading = false;
        }
    }

    async getDataToFullPage(): Promise<void> {
        if (!this.accessLockMonthPage) {
            await this.doRequest();
            await this.$nextTick();
            const rect = (this.$refs.CalendarBody.$el as HTMLElement).getBoundingClientRect();
            if (this.meta && this.meta.next_page && (rect.bottom <= (window.innerHeight + rect.top))) {
                await this.getDataToFullPage();
            }
        } else {
            await this.doFakeRequest();
        }
    }

    async mounted(): Promise<void> {
        await this.getDataToFullPage();
        this.fetchCalendarInformation(this.$route.params.id);
        window.addEventListener(`scroll`, this.handleScroll);
    }
}
</script>

<style lang='sass'>
.b-calendar-wrapper
    padding-bottom: 74px
    position: relative
    background: #fff

    &__info-fixed
        position: fixed
        bottom: 40px
        width: 270px
        left: 40px

    &:before
        content: ''
        width: 350px
        height: 100%
        position: absolute
        background: #F6F8F9

    &--spinner-with-filters
        position: absolute
        top: 200px
        left: auto
        margin: auto
        display: flex
        align-items: center
        justify-content: center
        right: auto
        width: 100%
        z-index: 33

    &__no-data
        margin-top: 20px
        font-size: 18px
        text-align: left
        padding-left: 20px
        padding-left: 400px
</style>
