<template>
<div>
    <div class='b-settings__title'>
        <span>
            {{ $t('SETTINGS.MENU.MANAGERS') }}
            <template v-if='!(loading && !totalPage)'>
                ({{ totalPage }})
            </template>
        </span>
        <div class='h-flex h-flex-center qa-add-manager__search'>
            <FwInput
                class='b-settings-managers__search'
                :value='searchText'
                :placeholder='$t(`LABEL.SEARCH.BY.LAST`)'
                type='text'
                inputStyle='white-bigger-search'
                iconLeft='loupe'
                iconLeftSize='15'
                @update:value='value => searchText = value'>
            </FwInput>
            <FwButton
                class='h-ml-20'
                min-width='50px'
                min-height='50px'
                color='white'
                style='background: #fff'
                borderRadiusType='small-border'
                paddingHorizontal
                size='base'
                @click='moveToAdvancedSearch'>
                <FwIcon icon='filter-list'
                        size='24'
                        color='#BEC7D3'>
                </FwIcon>
            </FwButton>
        </div>
    </div>
    <div class='b-settings-managers__sort__wrapper'>
        <div class='fw-select-base b-settings-managers__sort'>
            <FwSelect
                v-model='sortValue'
                :propsPlaceholder='`${$t(`BUTTON.SORT_BY`)}: ${$t(`LABEL.LAST.NAME`)}`'
                type='sort-simple'
                :withCircles='true'
                :canNotDelete='true'
                label='title'
                :options='sortByOptions'
                @input='doSearch'>
            </FwSelect>
        </div>

        <FwButton
            v-if='canCreate'
            class='h-mt-20 qa-add-manager-button'
            size='little'
            borderRadiusType='small-border'
            @click.native='openAddManagerPopup'>
            {{ $t('BUTTON.ADD') }}
        </FwButton>
    </div>

    <div>
        <FwSpinLoader
            v-if='loading'
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='loading'
            className='h-p-20'>
        </FwSpinLoader>
        <div v-else
             class='h-flex h-flex-wrap h-mh--10 h-mt-20'>
            <div v-for='manager in managers'
                 :key='manager.id'
                 class='h-width-50p h-ph-10'>
                <ManagerCard
                    class='h-mb-20'
                    :manager='manager'
                    :canRead='canRead'
                    @click.native='canRead ? openManagerPopup(manager) : null'>
                </ManagerCard>
            </div>
            <FwPagination
                v-if='totalPage > perPage'
                :totalPage='totalPage'
                :currentPage='currentPage'
                :perPage='perPage'
                :value='perPage'
                @getData='updateState'>
            </FwPagination>
        </div>
    </div>

    <div v-if='managerLoading'
         class='b-fw-spin-loader-wrapper'>
        <FwSpinLoader
            :colors='["#27dbbd", "#27DBBD", "#27DBBD"]'
            class='h-flex-center loader'
            :isActive='managerLoading'
            className='h-p-20'>
        </FwSpinLoader>
    </div>
    <AddManagerPopup
        v-else-if='isManagerPopupOpen'
        :manager='currentUser'
        @getUsers='doRequest'
        @close='isManagerPopupOpen = false'>
    </AddManagerPopup>
</div>
</template>

<script lang='ts'>
import throttle from 'lodash.throttle';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AddManagerPopup } from '@/components/popups/AddManagerPopup';
import { ManagerCard } from '@/components/simple/ManagerCard';
import i18n from '@/locale';
import { SettingsWebApi } from '@/api/settings/SettingsApi';
import { GetManagerPayload, SettingsManagerListType } from '@/types/Settings';
import PermissionsMixin from '@/mixins/permissions';
import { User } from '@/types/User';
import { updateUrlPageAndQuery } from '@/helpers/url';

const ConsultStore = namespace('ConsultStore');

@Component({
    components: {
        AddManagerPopup,
        ManagerCard,
    },
})
export default class SettingsManagersPage extends Mixins(PermissionsMixin) {
    @ConsultStore.Mutation('setAdvancedSearch') setAdvancedSearch!: (data: null) => void;

    currentPage: number = 1;
    totalPage: number | null = 0;
    perPage: number = 8;
    loading: boolean = false;
    managerLoading: boolean = false;
    isManagerPopupOpen: boolean = false;
    searchText: string = '';
    sortByOptions: Array<{ type : string, title: str, serverValue?: string }> = [
        {
            type: 'last_name',
            title: i18n.tc('LABEL.LAST.NAME'),
        },
        {
            type: 'first_name',
            title: i18n.tc('LABEL.FIRST.NAME'),
            serverValue: 'true',
        },
    ];
    managers: Array<SettingsManagerListType> = [];
    currentUser: User | null = null;
    sortValue: { type : string, title: str, serverValue?: string } | null = null;
    throttleSearch = throttle(this.search, 1500, { leading: false, trailing: true });
    unwatch = () => { };

    updateState(page: number = this.currentPage): void {
        this.$router.push(updateUrlPageAndQuery(`/settings/managers`, page, {
            query: this.searchText,
            sort: this.sortValue ? this.sortValue.serverValue || '' : '',
        }));
    }

    moveToAdvancedSearch() {
        this.setAdvancedSearch(null);
        this.$router.push({ path: `/consult/choose/managers/search` });
    }

    search(): void {
        this.currentPage = 1;
        this.updateState();
    }

    async doSearch() {
        this.updateState(1);
    }

    openAddManagerPopup() {
        this.currentUser = null;
        this.isManagerPopupOpen = true;
    }

    async openManagerPopup(manager: SettingsManagerListType) {
        this.managerLoading = true;
        this.currentUser = await SettingsWebApi.getManager(manager.id);
        this.managerLoading = false;
        this.isManagerPopupOpen = true;
    }

    takeParamsFromUrl(): void {
        this.unwatch();
        this.currentPage = this.$route.params.page ? parseInt(this.$route.params.page, 10) : 1;
        if (this.$route.query.sort) {
            const sortType = this.sortByOptions.find(item => item.serverValue === this.$route.query.sort);
            this.sortValue = sortType || this.sortByOptions[0];
        }
        this.searchText = (this.$route.query.query as string) || ``;
        this.unwatch = this.$watch('searchText', this.throttleSearch);
    }

    async doRequest() {
        this.loading = true;
        const serverValue: undefined | string = this.sortValue ? this.sortValue.serverValue : undefined;
        const payload: GetManagerPayload = {
            current_page: this.currentPage,
            per_page: this.perPage,
            search: this.searchText,
            ...(serverValue && { sort: serverValue }),
        };
        const { data } = await SettingsWebApi.getManagersList(payload);
        this.managers = data.managers;
        this.totalPage = data.meta.total_count;
        this.loading = false;
    }

    created() {
        if (!this.$route.query.sort) {
            this.sortValue = this.sortByOptions[0];
        }
        this.takeParamsFromUrl();
        this.doRequest();
    }

    @Watch(`$route`)
    onRouterChange(): void {
        this.takeParamsFromUrl();
        this.doRequest();
    }
};
</script>

<style lang='sass'>
.h-flex-1-0-50p
    flex: 1 0 50%

.b-settings-managers
    &__search
        width: 350px

    &__sort
        width: 350px

        &__wrapper
            margin-top: 10px
            display: flex
            justify-content: space-between
            align-items: baseline
            height: 63px

    .pagination
        display: flex
</style>
