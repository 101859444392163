import { Component, Vue } from 'vue-property-decorator';
import {
    isCalizyAccount,
    isImaSanteAccount,
    isMgasAccount,
    isImhAccount,
    isMatmutAccount,
    isMmeAccount,
    isApizeeAccount,
    isApgisAccount,
    isMgefiAccount,
    isHesAccount,
    isAlmAccount,
    isPlanifAccount,
    isMondialAccount,
} from '@/helpers/account';

@Component
export default class AccountMixin extends Vue {
    get isImaSanteAccount(): boolean {
        return isImaSanteAccount(this.user());
    }

    get isCalizyAccount(): boolean {
        return isCalizyAccount(this.user());
    }

    get isMgasAccount(): boolean {
        return isMgasAccount(this.user());
    }

    get isImhAccount(): boolean {
        return isImhAccount(this.user());
    }

    get isMatmutAccount(): boolean {
        return isMatmutAccount(this.user());
    }

    get isMmeAccount(): boolean {
        return isMmeAccount(this.user());
    }

    get isApizeeAccount(): boolean {
        return isApizeeAccount(this.user());
    }

    get isApgisAccount(): boolean {
        return isApgisAccount(this.user());
    }

    get isMgefiAccount(): boolean {
        return isMgefiAccount(this.user());
    }

    get isHesAccount(): boolean {
        return isHesAccount(this.user());
    }

    get isAlmAccount(): boolean {
        return isAlmAccount(this.user());
    }

    get isMondialAccount(): boolean {
        return isMondialAccount(this.user());
    }

    get mainLogo(): string {
        if (this.isAlmAccount) {
            return this.user().account_name;
        }

        return 'logo';
    }

    get isPlanifAccount(): boolean {
        return isPlanifAccount(this.user());
    }
};
