<template>
<div class='fw-time-card'>
    <div class='fw-time-card__time-wrapper'>
        <div class='fw-time-card__label h-flex h-flex-center'
             :style='{
                 backgroundColor: borderColor,
                 borderColor
             }'>
        </div>
        <div class='h-flex-center'>
            <div class='fw-time-card__time'>
                {{ startDate }}
            </div>
            <div class='fw-time-card__time'>
                {{ endDate }}
            </div>
        </div>
        <div v-if='data.distance_to_in_km_text'
             class='fw-time-card__distance'>
            {{ data.distance_to_in_km_text }}
        </div>

        <div v-if='isNumberExists'
             class='fw-border-index
                    h-pos-abs'
             :style='{ backgroundColor: color }'>
            {{ number }}
        </div>
    </div>

    <div v-if='isViewedShow'
         class='fw-time-card__viewed'>
        <FwIcon
            icon='eye-alt'
            size='11'
            color='#ff004d'>
        </FwIcon>
    </div>
</div>
</template>

<script lang='ts'>
import { namespace } from 'vuex-class';
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { BookingEventType } from '@/types/Availabilities';
import DateMixin from '@/mixins/dateMixin';
import { hexToRgbA } from '@/helpers/colors';
import { BranchSitesDataType } from '@/types/Site';
import AccountMixin from '@/mixins/account';

const AppointmentStore = namespace('AppointmentStore');

@Component
export default class BookCalendarEventCard extends Mixins(DateMixin, AccountMixin) {
    @AppointmentStore.State('viewedSlotsList') viewedSlotsList!: Array<string>;

    @Prop({ type: Object, required: true }) readonly data!: BookingEventType;
    @Prop({ type: Boolean, default: false }) readonly isOnSiteMethod!: boolean;

    get color() {
        if (!this.data.priority) {
            return `rgba(113, 143, 157, 0.8)`;
        }
        const priorityColors: Array<string> = [
            `#27DBBD`,
            `#24B6E7`,
            `#FFC500`,
            `#FF901D`,
            `#FF004D`,
        ];
        const count = this.data.priority - 1;
        return priorityColors[count] || priorityColors[0];
    }

    get number(): number | null | undefined {
        if (Number.isInteger(this.data.appointment_count_per_day) && !this.isAlmAccount) {
            return this.data.appointment_count_per_day;
        }
        if (Number.isInteger(this.data.min_count)) {
            return this.data.min_count;
        } else if (Number.isInteger(this.data.priority)) {
            return this.data.priority;
        }
        return null;
    }

    get isViewedShow(): boolean {
        return this.isMatmutAccount && this.isOnSiteMethod && this.viewedSlotsList.includes(`${this.data.slot}_${this.data.technician_id}`);
    }

    get isNumberExists() {
        return Number.isInteger(this.number);
    }

    get startDate() {
        return this.getCurrentDateAsMinutes(new Date(this.data.slot));
    }

    get borderColor(): string {
        if (this.data && this.data.technician_detail_color) {
            return this.data.technician_detail_color.includes(` - `) ? hexToRgbA(this.data.technician_detail_color.split(' - ')[0], 0.8) : this.data.technician_detail_color;
        }
        return this.color || `blue`;
    }
}
</script>

<style lang='sass'>
.fw-time-card
    padding: 2px 4px 2px 10px !important
    cursor: pointer
    width: 70px !important
    max-width: 70px !important
    height: 34px
    margin: 0 10px
    z-index: 1
    transition: transform 0.2s, box-shadow 0.2s

    &__outer
        width: 100%

    &__distance
        font-size: 11px
        display: flex

    &__time-wrapper
        padding-left: 4px

    &__viewed
        position: absolute
        right: 1px
        bottom: 2px
        height: 16px
        width: 10px

    .fw-time-card__label
        width: 5px

    .fw-border-index
        width: 12px
        height: 12px
        border-radius: 0 4px 0 4px
        top: 0
        right: 0
        text-align: center
        font-weight: 500
        position: absolute
        z-index: 1
        color: #fff
        font-size: 8px
        display: flex
        align-items: center
        justify-content: center
</style>
