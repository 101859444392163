export default {
    'BUTTON.NEXT': 'Next',
    'BUTTON.SAVE': 'Save',
    'BUTTON.ADD': 'Add',
    'BUTTON.SEARCH': 'Search',
    'BUTTON.EDIT': 'Edit',
    'BUTTON.ADD.NEW': 'Add new',
    'BUTTON.CONFIRM': 'Confirm',
    'BUTTON.CONFIRM2': 'Confirm',
    'BUTTON.CONFIRM.HOURS': 'Confirm working hours',
    'BUTTON.ADD.MORE': 'Add more',
    'BUTTON.SORT_BY': 'Sort by',
    'BUTTON.SUBMIT': 'Submit',
    'BUTTON.BACK': 'back',
    'BUTTON.LATER': 'Later',
    'BUTTON.CANCEL': 'Cancel',
    'BUTTON.CONSULT.RECAP': 'Consult recap',
    'BUTTON.SKIP': 'Skip',
    'BUTTON.SKIP.DIRECT': 'Skip',
    'BUTTON.CLEAR': 'Clear',
    'BUTTON.CLEAR.ALL': 'Clear All',
    'BUTTON.PASS': 'Pass',
    'BUTTON.QUIT': 'Quit',
    'BUTTON.CREATE': 'Create',
    'BUTTON.SEE_CALENDAR': 'See calendar',
    'BUTTON.MONTH_CALENDAR': 'Month calendar',
    'BUTTON.UNLOCK': 'Unlock',
    'HEADER.PLAN': 'Plan',
    'HEADER.CONSULT': 'Consult',
    'HEADER.CANCEL': 'Cancel',
    'HEADER.SETTINGS': 'Settings',
    'HEADER.HELP': 'Help',
    'HEADER.INFORMATION': 'Information',
    'LABEL': 'Label',
    'LABEL.ADDRESS': 'Address',
    'LABEL.ADDRESS.POST.CODE': 'Post code on this address does not exist, Please write post code in input or change address',
    'LABEL.ADDRESS.1': 'Address 1',
    'LABEL.ADDRESS.2': 'Address 2',
    'LABEL.ZIP.CODE': 'ZIP code',
    'LABEL.CODE.INSEE': 'INSEE Code',
    'LABEL.CITY': 'City',
    'LABEL.CODE.SIREN': 'SIREN',
    'LABEL.CODE.SIRET': 'SIRET',
    'LABEL.PHONE.NUMBER': 'Phone number',
    'LABEL.EMAIL': 'Email',
    'LABEL.EMAIL.ADDRESS': 'Email address',
    'LABEL.NOTES': 'Notes',
    'LABEL.MANAGER': 'Manager',
    'LABEL.SELECT.PLACEHOLDER': 'Choose a date',
    'LABEL.ADVISOR': 'Advisor',
    'LABEL.TECHNICIANS': 'Technicians',
    'LABEL.TECHICIANS.NUMBER': 'Number of technicians',
    'LABEL.EMPLOYEES.NUMBER': 'Number of employees',
    'LABEL.ROOMS': 'Rooms',
    'LABEL.HOURS': 'hours',
    'LABEL.MINUTES': 'minutes',
    'LABEL.NO.IMAGE': 'No image',
    'LABEL.AT': 'at',
    'LABEL.NEW.APPOINTMENT': 'New appointment',
    'LABEL.SEARCH.CLIENT': 'Search client',
    'LABEL.BY.PROSPECT.CUSTOMER': 'By prospect/customer ID',
    'LABEL.BY.CLIENT.EMAIL': 'By client email',
    'LABEL.BY.CLIENT.PHONE': 'By client phone',
    'LABEL.CATEGORY': 'Category',
    'LABEL.TYPE': 'Type',
    'LABEL.SELECT.TYPE': 'Select a type',

    'LABEL.REQUIRED.FIELD': 'Required field',

    'LABEL.TEAM.NAME': 'Nom de l\'équipe',
    'LABEL.TEAM.PLACEHOLDER': 'Nom de l\'équipe',

    'LABEL.SEARCH.BY.LAST': 'Search by last name',

    'LABEL.COMPANY': 'Company',
    'LABEL.NUMBER_ADVISOR_SERVICE_POINT': 'Number of Maximum Advisors Per Service Point',
    'LABEL.COMPANY.NAME': 'Company name',
    'LABEL.COMPANIES': 'Companies',
    'LABEL.WORKERS': 'Workers',
    'LABEL.WORKERS.PLURAL': 'Worker | Workers',
    'LABEL.YOUR.COMPANY': 'Your account',
    'LABEL.NAME': 'Name',
    'LABEL.SERVICE_POINT.ID': 'Service point ID',
    'LABEL.TOPIC.TO.DISCUSS': 'Topics to discuss',
    'LABEL.BRANCH.ID': 'Branch ID',
    'LABEL.ID': 'Id',
    'LABEL.EXTERNAL.ID': 'External ID',
    'LABEL.APPOINTMENT.METHOD': 'Appointment method',
    'LABEL.APPOINTMENT.METHODS': 'Appointment methods',
    'LABEL.ADVISOR.BY.DEFAULT': 'This advisor is by default:',
    'LABEL.GEOGRAFIC.ZONE': 'Geographic zone',
    'LABEL.METHOD': 'Method',
    'LABEL.LINK': 'Link',
    'LABEL.METHODS': 'Methods',
    'LABEL.CONTACT': 'Contact',
    'LABEL.LOGO': 'Logo',
    'LABEL.QUALIFICATIONS': 'Qualifications',
    'LABEL.ADDRESS.TWO': 'Address 2',
    'LABEL.START.POINT': 'Starting point',
    'LABEL.PHONE': 'Phone',
    'LABEL.ADMIN': 'Admin',
    'LABEL.GEOGRAPHIC.ZONE': 'Geographic zone',
    'LABEL.GEOGRAPHIC.ZONE.CITIES': 'Select cities',
    'LABEL.GEOGRAPHIC.ZONE.DISTRICTS': 'Select districts',
    'LABEL.GEOGRAPHIC.ZONE.DISTRICTS.SEARCH': 'Search a district',
    'LABEL.DELETE': 'DELETE',
    'LABEL.SKILLS': 'Skills',
    'LABEL.SKILL': 'Skill',
    'LABEL.SKILLS.NEEDED': 'Skills needed',
    'LABEL.LOCATION.APPT': 'Location',
    'LABEL.NO': 'No',
    'LABEL.YES': 'Yes',
    'LABEL.PLACEHOLDER.SEARCH': 'Search',
    'LABEL.PLACEHOLDER.APPOINTMENT': 'Give a name to this appointment',
    'LABEL.DISPLAY.PARAMETERS': 'Display parameters',
    'LABEL.APP.AVAILABILITY': 'App availability',
    'LABEL.APP.VISIBILITY': 'Visibility in Calizy Selfcare',
    'LABEL.MAIN': 'Main title',
    'LABEL.SELFCARE.PLACEHOLDER': 'Title in Calizy Selfcare',
    'LABEL.DISPLAY.ORDER': 'Display order',
    'LABEL.ORDER.PLACEHOLDER': 'ex: 1',
    'LABEL.PLACEHOLDER.CHOOSE_LANG': 'Choose language',
    'LABEL.PASSWORD': 'Password',
    'LABEL.BOOK': 'Book',
    'LABEL.NO.RESULT': 'No result',
    'LABEL.ALL': 'All',
    'LABEL.ALL.ADVISORS': 'advisors',
    'LABEL.RESULT.HERE.INFO': 'Here you will see the result',
    'LABEL.PERSONALISED.LINK': 'Personalised Link',
    'LABEL.AUTOMATIC.LINK': 'Automatic Link',

    'TITLE.MANAGER.ADVANCED.SEARCH': 'Manager advanced search',
    'TITLE.ADVISOR.ADVANCED.SEARCH': 'Advisor advanced search',
    'LABEL.TYPE.FIRST.NAME': 'Type first name',
    'LABEL.TYPE.LAST.NAME': 'Type last name',
    'LABEL.TYPE.EMAIL': 'Type email',
    'LABEL.SELECT.SKILL': 'Select skill',
    'LABEL.SELECT.APPOINTMENT.METHOD': 'Select appointment method',
    'LABEL.SELECT.ROLE': 'Select role',
    'LABEL.SUB.ACCOUNTS': 'Sub-accounts',
    'LABEL.SELECT.SUB.ACCOUNTS': 'Select sub-accounts',
    'LABEL.SUB.ACCOUNT': 'Sub-account',
    'LABEL.SELECT.SUB.ACCOUNT': 'Select sub-account',

    'LOADING.TEXT': 'LOADING...',
    'FINISH.POPUP.SUCCESS.TITLE': 'Success!',
    'FINISH.POPUP.SUCCESS.CLOSE': 'Close',
    'VALIDATION.FIELD.REQUIRED': 'This field is required',
    'VALIDATION.FIELD.EMAIL': 'Invalid email',
    'VALIDATION.FIELD.CHARACTERS.MAX': 'Must have up to 10 characters',
    'VALIDATION.FIELD.CHARACTERS.REQUIRED': 'Must have at least 2 characters',
    'VALIDATION.FIELD.PASSWORD.MIN': 'Password should be at least 6 characters',
    'WRONG.CODE': 'Please enter a valid intervention code',

    'VALIDATION.EMAIL_OR_PHONE.REQUIRED': 'Email or phone is required',
    'VALIDATION.FIRST_OR_LAST_NAME.REQUIRED': 'First name or last name is required',

    'LABEL.CREATE': 'Create',
    'LABEL.DESTROY': 'Delete',
    'LABEL.LIST': 'List',
    'LABEL.READ': 'Read',
    'LABEL.EDIT': 'Edit',
    'LABEL.HAVE.NOT.DATA.FOR.PERIOD': 'There is no data for the period selected.',
    'LABEL.HAVE.NOT.DATA.FOR.POST_CODE': 'There is no data for the postcode selected.',
    'LABEL.VIDEO.CONFERENCE.URL': 'Video conference link',

    'LABEL.APPOINTMENT.TYPE': 'Appointment type',

    'GLOBAL.TECHNICIAN': 'Technician',

    'GLOBAL.ELEMENT.PLURAL': 'Element | Elements',
    'GLOBAL.TYPE': 'Type',
    'GLOBAL.RECIPIENT': 'Recipient',
    'GLOBAL.WHEN': 'When',
    'GLOBAL.CATEGORY': 'Category',
    'GLOBAL.TITLE': 'Title',
    'GLOBAL.DURATION': 'Duration',
    'GLOBAL.SKILLS.METHOD': 'Skills method',
    'GLOBAL.METHOD': 'Method',
    'GLOBAL.SYNCHRONISATION': 'Synchronizations',
    'GLOBAL.CANCELLED': 'Cancelled',

    'HEADER.EDIT': 'Edit informations',
    'LABEL.GEO.ZONE': 'add a district/city',

    'NOTIFICATION.BAR.SUCCESS.CREATED': 'Successfully created',
    'NOTIFICATION.BAR.SUCCESS.UPDATED': 'Successfully updated',
    'NOTIFICATION.BAR.SUCCESS.BAR.REMOVED': 'Successfully removed',
    'NOTIFICATION.BAR.SUCCESS.CONFIRMED': 'Successfully confirmed',
    'NOTIFICATION.BAR.SUCCESS.CANCELED': 'Successfully cancel',
    'ERROR.IMAGE.FORMAT': 'This format is not supported. Format supported are : PNG, JPEG',
    'ERROR.IMAGE.MAXLIMIT': 'The size of this file is too big. The maximum size supported is 3mb',

    'LABEL.CALIZY_SAAS_APPT': 'Calizy SaaS appointment | Calizy SaaS appointments',
    'LABEL.CALIZY_SELFCARE_APPT': 'Calizy Selfcare appointment | Calizy Selfcare appointments',
    'LABEL.UNAVAILABILITY': 'unavailability | unavailabilities',
    'LABEL.RESERVED_SLOT': 'Reserved slot | Reserved slots',
    'GLOBAL.FROM': 'From',
    'GLOBAL.TO': 'To',

    'LABEL.COUNTRY': 'Country',
    'LABEL.COUNTRY.ISO': 'Country ISO',
    'LABEL.SELECT.COUNTRY': 'Select country',
};
